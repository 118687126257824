import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  Button,
  withStyles,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Divider,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField,
  InputLabel,
  FormControl,
  Select,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Logo from 'src/components/Logo';
import PersonIcon from '@material-ui/icons/Person';
import UserContext from 'src/context/UserContext';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ReceiptIcon from '@material-ui/icons/Receipt';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { createLead, leadReducer } from 'src/redux/lead';
import toast, { Toaster } from 'react-hot-toast';
import { getSkills, jobReducer } from 'src/redux/jobs';
import { Autocomplete } from '@material-ui/lab';
import { getLoggedInUserData } from 'src/App';

const useStyles = makeStyles((theme) => ({
  // root: {},
  avatar: {
    width: 60,
    height: 60,
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    textTransform: 'capitalize',
    borderRadius: '5px',
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    textTransform: 'capitalize',
    borderRadius: '10px',
    padding: '15px 25px',

    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  formControl: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '10px', // Add border-radius here
    },
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },
});

const TopBar = memo(({ className, onMobileNavOpen, ...rest }) => {
  const navigation = useNavigate();
  const [userData, setUserData] = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [signUpModal, setSignUpModal] = React.useState(false);

  const { loading } = useSelector(leadReducer);
  const { skillsList } = useSelector(jobReducer);

  const [listOfSkill, setListOfSkill] = useState([]);

  let classes = useStyles();
  let dispatch = useDispatch();

  const fetchData = async () => {
    let resp = await dispatch(getSkills());
    if (resp.status === 200) {
      setListOfSkill(resp.data);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.removeItem('userDetails');
    setUserData(null);
    navigation('/auth/login', { replace: true });
    window.location.reload();
  };

  const StyledMenu = withStyles({
    paper: {
      border: '1px solid #d3d4d5',
    },
  })((props) => (
    <Menu
      // elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&:focus': {
        backgroundColor: 'RGB( 129, 183, 52 )',
        '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
          color: theme.palette.common.white,
        },
      },
    },
  }))(MenuItem);
  //! METHODS
  const openSignUpModal = () => {
    setSignUpModal(true);
  };
  const closeSignUpModal = () => {
    setSignUpModal(false);
  };

  const validationSchema = yup.object({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('First Name is required'),
    Profession: yup.string().required('A/C Type is required'),
    companyName: yup.string().required('Company Name is required'),
    email: yup.string().required('Email is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
  });
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      Profession: '',
      companyName: '',
      email: '',
      phoneNumber: '',
      customdata: '',
      message: '',
      packageType: 'Basic',
      panNumber: '',
      leadId: 0,
      isConverted: true,
      scopeOfWork: [],
    },
    validationSchema: validationSchema,
    enableReinitialize: false,
    onSubmit: async (values) => {
      let loadingToast = toast.loading('Loading...');
      let resp = await dispatch(
        createLead({
          ...values,
          scopeOfWork: values.scopeOfWork.map((item) => item.name).join(', '),
        })
      );
      if (resp.status === 200) {
        formik.resetForm();
        closeSignUpModal();
        toast.dismiss(loadingToast);
        toast.success('User Created Successfully');
      }
      toast.dismiss(loadingToast);

      if (resp?.data?.error) {
        return toast.error(resp.data.error);
      }
    },
  });

  const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });
  return (
    <AppBar
      elevation={0}
      {...rest}
      style={{ background: '#F3F6F8', zIndex: '1' }}
    >
      <Toaster />

      <Dialog
        keepMounted
        scroll="body"
        open={signUpModal}
        onClose={closeSignUpModal}
        fullWidth="sm"
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle id="customized-dialog-title" onClose={closeSignUpModal}>
          Create New Bettamint Account
        </DialogTitle>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            {/* <Field title="First Name"> */}
            <Grid style={{ paddingBottom: '5px' }}>
              <Typography style={{ fontWeight: 500 }}>First Name* </Typography>
            </Grid>
            <TextField
              variant="outlined"
              fullWidth
              className={classes.formControl}
              // label="First Name"
              name="firstName"
              id="firstName"
              value={formik.values.firstName}
              onChange={(e) => {
                formik.setFieldValue('firstName', e.target.value);
              }}
              required
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              style={{ paddingBottom: '1rem' }}
            />
            {/* </Field>
            style={{ paddingBottom: '1rem' }} */}
            {/* <Field title="Last Name"> */}
            <Grid style={{ paddingBottom: '5px' }}>
              <Typography style={{ fontWeight: 500 }}>Last Name* </Typography>
            </Grid>
            <TextField
              variant="outlined"
              fullWidth
              className={classes.formControl}
              // label="Last Name"
              name="lastName"
              id="lastName"
              value={formik.values.lastName}
              onChange={formik.handleChange}
              required
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              style={{ paddingBottom: '1rem' }}
            />
            {/* </Field>
            style={{ paddingBottom: '1rem' }} */}
            {/* <Field title="Account Type"> */}
            <Grid style={{ paddingBottom: '5px' }}>
              <Typography style={{ fontWeight: 500 }}>
                Account Type*{' '}
              </Typography>
            </Grid>
            <FormControl
              className={classes.formControl}
              fullWidth
              style={{ paddingBottom: '1rem' }}
            >
              <Select
                variant="outlined"
                type="submit"
                fullWidth
                name="Profession"
                id="Profession"
                value={formik.values.Profession}
                onChange={formik.handleChange}
                required
              >
                <MenuItem value="Developer">Developer</MenuItem>
                <MenuItem value="Contractor">General Contractor</MenuItem>
                <MenuItem value="SubContractor">Sub Contractor</MenuItem>
                <MenuItem value="LabourContractor">Labour Contractor</MenuItem>
                <MenuItem value="Individual">Individual</MenuItem>
                <MenuItem value="Consultant">Consultant</MenuItem>
              </Select>
            </FormControl>

            {!['Developer', ''].includes(formik.values.Profession) && (
              <>
                <Grid style={{ paddingBottom: '5px' }}>
                  <Typography style={{ fontWeight: 500 }}>
                    Scope Of Work*{' '}
                  </Typography>
                </Grid>
                <Autocomplete
                  loading={!skillsList}
                  name="scopeOfWork"
                  value={formik.values.scopeOfWork}
                  onChange={(event, newValue) => {
                    formik.setFieldValue('scopeOfWork', newValue);
                  }}
                  multiple
                  id="scopeOfWork"
                  options={
                    Array.isArray(skillsList) && skillsList?.length > 0
                      ? skillsList
                      : []
                  }
                  getOptionLabel={(option) => (option?.name ? option.name : '')}
                  renderInput={(params) => (
                    <TextField
                      error={
                        formik.touched.scopeOfWork &&
                        Boolean(formik.errors.scopeOfWork)
                      }
                      helperText={
                        formik.touched.scopeOfWork && formik.errors.scopeOfWork
                      }
                      {...params}
                      className={classes.formControl}
                      variant="outlined"
                      style={{ paddingBottom: '1rem' }}
                    />
                  )}
                />
              </>
            )}
            <Grid style={{ paddingBottom: '5px' }}>
              <Typography style={{ fontWeight: 500 }}>
                Company Name/Proprietor's Name*{' '}
              </Typography>
            </Grid>
            <TextField
              variant="outlined"
              fullWidth
              className={classes.formControl}
              // label="Company Name/Proprietor's Name"
              name="companyName"
              id="companyName"
              value={formik.values.companyName}
              onChange={formik.handleChange}
              required
              error={
                formik.touched.companyName && Boolean(formik.errors.companyName)
              }
              helperText={
                formik.touched.companyName && formik.errors.companyName
              }
              style={{ paddingBottom: '1rem' }}
            />
            <Grid style={{ paddingBottom: '5px' }}>
              <Typography style={{ fontWeight: 500 }}>
                Email Address*{' '}
              </Typography>
            </Grid>
            <TextField
              variant="outlined"
              fullWidth
              className={classes.formControl}
              // label="Email Address"
              name="email"
              type="email"
              id="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              required
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              style={{ paddingBottom: '1rem' }}
            />
            {/* </Field>
            style={{ paddingBottom: '1rem' }} */}
            {/* <Field title="Phone Number"> */}
            <Grid style={{ paddingBottom: '5px' }}>
              <Typography style={{ fontWeight: 500 }}>
                Phone Number*{' '}
              </Typography>
            </Grid>
            <TextField
              variant="outlined"
              fullWidth
              className={classes.formControl}
              // label="Phone Number"
              name="phoneNumber"
              id="phoneNumber"
              type="tel"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              required
              error={
                formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
              }
              helperText={
                formik.touched.phoneNumber && formik.errors.phoneNumber
              }
              style={{ paddingBottom: '1rem' }}
            />
            {/* </Field> */}
            <Button
              type="submit"
              className={classes.buttonPurple}
              // style={{ marginTop: '.5rem' }}
              fullWidth
            >
              {loading ? 'Submitting...' : 'Submit'}
            </Button>
          </DialogContent>
        </form>
      </Dialog>

      <Toolbar>
        <RouterLink to="/app/dashboard">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Grid container justifyContent="flex-end">
          {getLoggedInUserData()?.user?.role?.name === 'SuperAdmin' && (
            <Button
              onClick={openSignUpModal}
              className={classes.buttonGreen}
              style={{ width: '130px !important', marginRight: '1rem' }}
            >
              Create Account
            </Button>
          )}
        </Grid>
        <div>
          <Typography
            // aria-controls="customized-menu"
            // aria-haspopup="true"
            // variant="contained"
            // color="primary"
            align="center"
            style={{
              color: '#333',
              cursor: 'pointer',
              fontSize: '12px',
              display: 'flex',
              justify: 'center',
              alignItems: 'center',
            }}
            onClick={handleClick}
          >
            {userData?.user?.fullName}
            <ArrowDropDownIcon style={{ color: '#333' }} />
          </Typography>
          <StyledMenu
            id="customized-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <StyledMenuItem
              selected={1 === selectedIndex}
              onClick={() => {
                handleClose();
                setSelectedIndex(1);
              }}
              color="black"
              component={RouterLink}
              to="profile"
            >
              <ListItemIcon>
                <PersonIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Profile" />
            </StyledMenuItem>
            <StyledMenuItem
              onClick={() => {
                handleClose();
                setSelectedIndex(2);
              }}
              selected={2 === selectedIndex}
              component={RouterLink}
              to="subscription"
            >
              <ListItemIcon>
                <ReceiptIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Subscription" />
            </StyledMenuItem>
            <Divider />
            <StyledMenuItem
              onClick={() => {
                handleLogout();
                handleClose();
              }}
            >
              <ListItemIcon>
                {/* <SendIcon fontSize="small" /> */}
                <ExitToAppIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </StyledMenuItem>
          </StyledMenu>
        </div>

        {/* <Button onClick={ handleLogout }>
					<ExitToAppIcon style={ { color: 'black' } } />
					Logout
				</Button>
				<IconButton color='black' component={ RouterLink } to='profile'>
					<PersonIcon style={ { color: 'black' } } />
				</IconButton> */}

        {/* <IconButton color='black' onClick={onMobileNavOpen}>
					<SettingsIcon style={{ color: 'black' }} />
				</IconButton>
				<IconButton color='black' onClick={onMobileNavOpen}>
					<NotificationsIcon style={{ color: 'black' }} />
				</IconButton> */}
        <Hidden lgUp>
          <IconButton color="black" onClick={onMobileNavOpen}>
            <MenuIcon style={{ color: 'black' }} />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
});

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;

// import { withStyles } from '@material-ui/core/styles';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import DraftsIcon from '@material-ui/icons/Drafts';
// import SendIcon from '@material-ui/icons/Send';

// const StyledMenu = withStyles( {
// 	paper: {
// 		border: '1px solid #d3d4d5',
// 	},
// } )( ( props ) => (
// 	<Menu
// 		elevation={ 0 }
// 		getContentAnchorEl={ null }
// 		anchorOrigin={ {
// 			vertical: 'bottom',
// 			horizontal: 'center',
// 		} }
// 		transformOrigin={ {
// 			vertical: 'top',
// 			horizontal: 'center',
// 		} }
// 		{ ...props }
// 	/>
// ) );

// const StyledMenuItem = withStyles( ( theme ) => ( {
// 	root: {
// 		'&:focus': {
// 			backgroundColor: theme.palette.primary.main,
// 			'& .MuiListItemIcon-root, & .MuiListItemText-primary': {
// 				color: theme.palette.common.white,
// 			},
// 		},
// 	},
// } ) )( MenuItem );

// export default function CustomizedMenus () {
// 	const [ anchorEl, setAnchorEl ] = React.useState( null );

// 	const handleClick = ( event ) => {
// 		setAnchorEl( event.currentTarget );
// 	};

// 	const handleClose = () => {
// 		setAnchorEl( null );
// 	};

// 	return (
// 		<div>
// 			<Button
// 				aria-controls="customized-menu"
// 				aria-haspopup="true"
// 				variant="contained"
// 				color="primary"
// 				onClick={ handleClick }
// 			>
// 				Open Menu
// 			</Button>
// 			<StyledMenu
// 				id="customized-menu"
// 				anchorEl={ anchorEl }
// 				keepMounted
// 				open={ Boolean( anchorEl ) }
// 				onClose={ handleClose }
// 			>
// 				<StyledMenuItem>
// 					<ListItemIcon>
// 						<SendIcon fontSize="small" />
// 					</ListItemIcon>
// 					<ListItemText primary="Sent mail" />
// 				</StyledMenuItem>
// 				<StyledMenuItem>
// 					<ListItemIcon>
// 						<DraftsIcon fontSize="small" />
// 					</ListItemIcon>
// 					<ListItemText primary="Drafts" />
// 				</StyledMenuItem>
// 				<StyledMenuItem>
// 					<ListItemIcon>
// 						<InboxIcon fontSize="small" />
// 					</ListItemIcon>
// 					<ListItemText primary="Inbox" />
// 				</StyledMenuItem>
// 			</StyledMenu>
// 		</div>
// 	);
// }
