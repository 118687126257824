import React, { useEffect, useState } from 'react';
import Page from 'src/components/Page';
import {
  Button,
  Container,
  Grid,
  makeStyles,
  Typography,
  Table,
  TableHead,
  CircularProgress,
  TableRow,
  TableBody,
  Checkbox,
  TableCell,
  TableContainer,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  Chip,
  withStyles,
  Tooltip,
  TablePagination,
} from '@material-ui/core';
import EventNoteIcon from '@material-ui/icons/EventNote';

import { useDispatch, useSelector } from 'react-redux';
import {
  attendanceReducer,
  getAttendance,
  getWorkerAttendance,
  selectWorker,
  showPayment,
} from 'src/redux/attendance';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import FilterProjectComponent from 'src/components/FilterProjectComponent';

import { useNavigate } from 'react-router-dom';
import SortSelection from 'src/components/SortSelection';
import SearchInput from 'src/components/SearchInput';
import { getSkills, jobReducer } from 'src/redux/jobs';
import es from 'date-fns/locale/es';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useToasts } from 'react-toast-notifications';
import defaultProjectImage from '../../assests/Projects Icon.png';
import {
  getPaymentHistory,
  getPaymentSummary,
  paymentReducer,
  sendPayment,
} from 'src/redux/payment';
import moment from 'moment';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import logo from '../../assests/logo.png';
import logoText from '../../assests/logo-text.png';
import FilterByLabourContractorComponent from 'src/components/FilterByLaourContractorComponent';
import UserContext from 'src/context/UserContext';
import { useContext } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { getContractors } from 'src/redux/users';
import BettaPayLogo from '../../assests/BettaPayLogo.png';
import PayrollModalComponent from 'src/components/PayrollModalComponent';
import { RechargeBanner } from './components/RechargeBanner';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import PaymentCardTiles from './components/PaymentCardTiles';
import vector from '../../assests/Vector.png';
import useAccessPerView from 'src/common/Access';

registerLocale('es', es);

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    // paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    // width: '8em',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  customBadge: {
    backgroundColor: '#81B734',
    color: 'white',
  },
  table: {
    minWidth: 650,
  },
  headerButton: {
    fontSize: '12px',
    padding: '5px 15px',
    marginRight: '10px',
    '&:hover': {
      backgroundColor: '#81B734',
      color: 'white',
    },
  },
  typographyCommonStyle: {
    fontFamily: 'Roboto',
    color: '#4D6068',
  },
  greenFontColor: {
    fontFamily: 'Roboto',

    color: '#81B742',
  },
  textFontSize: {
    fontSize: '12px',
  },
  marginRight: {
    marginLeft: '5px',
  },
  marginBottom: {
    marginBottom: '0px',
  },
  [theme.breakpoints.down('sm')]: {
    headerButton: {
      marginRight: '0px',
    },
    buttonFullSize: {
      width: '100%',
      margin: '10px 0',
    },
    marginRight: {
      marginLeft: '0',
    },
    marginBottom: {
      marginBottom: '10px',
    },
  },
}));

function Payments() {
  const classes = useStyles();

  const [edit] = useState(false);
  const access = useAccessPerView('PAYROLL');

  const [classifiedProject, setClassifiedProject] = useState('');
  const [classifiedProjectHistory, setClassifiedProjectHistory] = useState('');

  const [currentProject, setCurrentProject] = useState('');
  const [currentProjectHistory, setCurrentProjectHistory] = useState('');
  const [LabourContractor, setLabourContractor] = useState(0);

  const [filteredArray, setFilteredArray] = useState([]);
  const [attendanceList, setAttendanceList] = useState([]);
  const [filters, setFilters] = useState({
    search: '',
    sortBy: 0,
    range: '',
  });
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [dateRangeHistory, setDateRangeHistory] = useState([null, null]);
  const [startDateHistory, endDateHistory] = dateRangeHistory;
  const [isPaymentHistoryOpened, setIsPaymentHistoryOpened] = useState(false);
  const [isInvoiceVisible, setIsInvoiceVisible] = useState(false);
  const [paymentHitoryModal, setPaymentHitoryModal] = useState(false);
  const [paymentSummarySelectedRow, setPaymenetSummarySelectedRow] =
    useState(false);
  const [payrollModal, setPayrollModal] = useState(false);

  const [totalSelectedWorkerAmount, setTotalSelectedWorkerAmount] = useState(0);
  const [openBanner, setOpenBanner] = useState(false);
  const [paginationData, setPaginationData] = useState({
    page: 0,
    rowsPerPage: 25,
    count: 100,
  });

  const [userData, setUserData] = useContext(UserContext);

  //! INSTANCES
  let dispatch = useDispatch();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  let currentUserRole = JSON.parse(localStorage.getItem('userDetails')).user
    .userTypeId;

  //! SELECTORS
  const { projectClassificationList } = useSelector(projectReducer);
  const {
    // error,
    loading: fetchLoading,
    attendanceList: listOfAttendance,
    // workersAttendanceList,
  } = useSelector(attendanceReducer);
  const { loading, skillsList } = useSelector(jobReducer);
  const {
    loading: offlinePaymentLoading,
    history,
    historyLoading,
    paymentSummary,
    paymentSummaryLoading,
  } = useSelector(paymentReducer);

  useEffect(() => {
    setPayrollModal(!access.isViewMode);
  }, [access.isViewMode]);

  //! LIFE-CYCLE-EVENTS
  useEffect(() => {
    dispatch(getProjectsClassificationList());

    if (
      listOfAttendance?.attendances?.length > 0 ||
      listOfAttendance?.attendances?.length === 0
    ) {
      setAttendanceList([...listOfAttendance?.attendances]);
    }
    if (projectClassificationList.length > 0 && currentProject === '') {
      dispatch(getSkills());
      dispatch(getAttendance(projectClassificationList[0]?.projectId));
      dispatch(getContractors(projectClassificationList[0]?.projectId));
      setLabourContractor(projectClassificationList[0]);

      setCurrentProject(projectClassificationList[0]);
      setClassifiedProject([projectClassificationList[0]]);
      setClassifiedProjectHistory([projectClassificationList[0]]);
      setCurrentProjectHistory(projectClassificationList[0]);
      // }
      // else {
      // 	dispatch(getAttendance(0));
      // 	setCurrentProject('');
      // 	setClassifiedProject('');
      // 	setClassifiedProjectHistory('');
      // 	setCurrentProjectHistory('');
      // }
      // }
    }
  }, [projectClassificationList.length, listOfAttendance?.attendances?.length]);

  // function createData(transactionId, projectId, date, payment) {
  // 	return { transactionId, projectId, date, payment };
  // }

  const classifyProject = (project) => {
    if (project) {
      const { projectId } = project;
      setLabourContractor(projectId);
      dispatch(getContractors(projectId));
      setCurrentProject(project);

      setClassifiedProject(
        projectClassificationList.filter(
          (project) => project.projectId === projectId && project,
        ),
      );
      dispatch(getAttendance(projectId, LabourContractor?.userId));
    } else {
      if (currentUserRole !== 'SuperAdmin') {
        setLabourContractor(projectClassificationList[0]?.projectId);
        dispatch(getContractors(projectClassificationList[0]?.projectId));
        setCurrentProject(projectClassificationList[0]);
        setClassifiedProject([projectClassificationList[0]]);
        dispatch(
          getAttendance(
            projectClassificationList[0]?.projectId,
            LabourContractor?.userId,
          ),
        );
      } else {
        setLabourContractor(0);
        dispatch(getContractors());
        setCurrentProject('');
        setClassifiedProject('');
        dispatch(getAttendance(0));
      }
    }
  };
  const classifyProjectHistory = (project) => {
    if (project) {
      const { projectId } = project;
      setCurrentProjectHistory(project);

      setClassifiedProjectHistory(
        projectClassificationList.filter(
          (projectHistory) =>
            projectHistory.projectId === projectId && projectHistory,
        ),
      );
      if (endDateHistory) {
        dispatch(
          getPaymentHistory(
            projectId,
            moment.utc(startDateHistory).format(),
            moment.utc(endDateHistory).format(),
          ),
        );
      }
    } else {
      setCurrentProjectHistory(projectClassificationList[0]);
      setClassifiedProjectHistory([projectClassificationList[0]]);
      if (endDateHistory) {
        dispatch(
          getPaymentHistory(
            projectClassificationList[0].projectId,
            moment.utc(startDateHistory).format(),
            moment.utc(endDateHistory).format(),
          ),
        );
      }
    }
  };

  // const object = [
  // 	{
  // 		id: 1,
  // 		name: 'Siska Construction Project',
  // 		skillSet: 'New Delhi',
  // 		startDate: '21 May, 2022',
  // 		endDate: '21 May, 2022',
  // 		earning: 'Plumbing Expert',
  // 	},
  // 	{
  // 		id: 2,
  // 		name: 'Siska Construction Project',
  // 		skillSet: 'New Delhi',
  // 		startDate: '21 May, 2022',
  // 		endDate: '21 May, 2022',
  // 		earning: 'Plumbing Expert',
  // 	},
  // 	{
  // 		id: 3,
  // 		name: 'Siska Construction Project',
  // 		skillSet: 'New Delhi',
  // 		startDate: '21 May, 2022',
  // 		endDate: '21 May, 2022',
  // 		earning: 'Plumbing Expert',
  // 	},
  // 	{
  // 		id: 4,
  // 		name: 'Siska Construction Project',
  // 		skillSet: 'New Delhi',
  // 		startDate: '21 May, 2022',
  // 		endDate: '21 May, 2022',
  // 		earning: 'Plumbing Expert',
  // 	},
  // ];

  // const renderViewPaymentDetails = () => (
  // 	<Grid container style={{ margin: 5 }}>
  // 		{loading ? (
  // 			<div
  // 				style={{
  // 					textAlign: 'center',
  // 					width: '100%',
  // 				}}>
  // 				<CircularProgress style={{ color: '#81B734' }} />
  // 			</div>
  // 		) : (
  // 			<>
  // 				<Grid
  // 					container
  // 					item
  // 					xs={12}
  // 					justify='space-between'
  // 					alignItems='center'
  // 					style={{ marginTop: '1em', marginBottom: '1em' }}>
  // 					<Grid item xs={4} container alignItems='center'>
  // 						<IconButton
  // 							onClick={() => {
  // 								setShowCard(true);
  // 								setEdit(false);
  // 								setCreateNewProject(false);
  // 							}}>
  // 							<ArrowBackIcon color='black' fontSize='default' />
  // 						</IconButton>
  // 						<Typography variant='h5' style={{ fontFamily: 'Roboto' }}>
  // 							Sukhi Ramchad
  // 						</Typography>
  // 					</Grid>
  // 					<Grid item xs={1}>
  // 						<Button className={classes.buttonPurpleOpacity}>Edit</Button>
  // 					</Grid>
  // 				</Grid>
  // 				<Grid
  // 					container
  // 					item
  // 					xs={12}
  // 					justify='space-between'
  // 					alignItems='flex-start'>
  // 					<Grid item xs={4}>
  // 						<Grid item xs={12}>
  // 							<img
  // 								src='/static/images/bettamintImages/worker2.png'
  // 								alt='project'
  // 								style={{
  // 									width: '95%',
  // 									// height: "90px",
  // 									textAlign: 'center',
  // 								}}
  // 							/>
  // 						</Grid>
  // 						<Grid item xs={12}>
  // 							<img
  // 								src='/static/images/bettamintImages/KYC.png'
  // 								alt='project'
  // 								style={{
  // 									width: '95%',
  // 									// height: "90px",
  // 									textAlign: 'center',
  // 								}}
  // 							/>
  // 						</Grid>
  // 					</Grid>
  // 					<Grid
  // 						container
  // 						item
  // 						xs={8}
  // 						justify='center'
  // 						style={{ marginTop: 20 }}>
  // 						<Grid
  // 							container
  // 							item
  // 							xs={12}
  // 							style={{ marginBottom: 10 }}
  // 							justify='center'>
  // 							<TextField
  // 								label='FULL NAME'
  // 								fullWidth
  // 								variant='filled'
  // 								value='Sukhi Ramchan Kumar'
  // 							/>
  // 						</Grid>
  // 						<Grid
  // 							container
  // 							item
  // 							xs={12}
  // 							justify='space-between'
  // 							style={{ marginTop: '1em', marginBottom: '1em' }}>
  // 							<Grid item xs={5}>
  // 								<TextField
  // 									label='SKILL SET'
  // 									fullWidth
  // 									variant='filled'
  // 									value='Electrician & Plumber'
  // 								/>
  // 							</Grid>
  // 							<Grid item xs={5}>
  // 								<TextField
  // 									variant='filled'
  // 									fullWidth
  // 									label='ID CARD NUMBER'
  // 									value='1234-5678-9102'
  // 								/>
  // 							</Grid>
  // 						</Grid>
  // 						<Grid
  // 							container
  // 							item
  // 							xs={12}
  // 							style={{ marginBottom: 10 }}
  // 							justify='center'>
  // 							<TextField
  // 								label='WORKER RATING'
  // 								fullWidth
  // 								variant='filled'
  // 								value='4.5 (502 Reviews)'
  // 							/>
  // 						</Grid>
  // 						<Grid
  // 							container
  // 							item
  // 							xs={12}
  // 							justify='space-between'
  // 							style={{ marginTop: '1em', marginBottom: '1em' }}>
  // 							<Grid item xs={5}>
  // 								<TextField
  // 									label='AVERAGE WAGES'
  // 									variant='filled'
  // 									fullWidth
  // 									value='₹ 945'
  // 								/>
  // 							</Grid>
  // 							<Grid item xs={5}>
  // 								<TextField
  // 									variant='filled'
  // 									fullWidth
  // 									label='SKILL LEVEL'
  // 									value='56%'
  // 								/>
  // 							</Grid>
  // 						</Grid>
  // 						<Grid
  // 							container
  // 							item
  // 							xs={12}
  // 							style={{ marginBottom: 10 }}
  // 							justify='center'>
  // 							<TextField
  // 								label='PHONE'
  // 								fullWidth
  // 								variant='filled'
  // 								style={{ width: '715px' }}
  // 								value='+91 11 2419 8000'
  // 							/>
  // 						</Grid>
  // 						<Grid
  // 							container
  // 							item
  // 							xs={12}
  // 							style={{ marginBottom: 10 }}
  // 							justify='center'>
  // 							<TextField
  // 								label='LOCATION'
  // 								fullWidth
  // 								variant='filled'
  // 								style={{ width: '715px' }}
  // 								value='V.V.Nagar, Tisaiyanvilai, Tirunelveli Natak vin 5703, New Dehli, India'
  // 							/>
  // 						</Grid>
  // 						<Grid
  // 							container
  // 							item
  // 							xs={12}
  // 							justify='space-between'
  // 							style={{ marginTop: '1em', marginBottom: '1em' }}>
  // 							<Grid item xs={5}>
  // 								<TextField
  // 									placeholder='Select'
  // 									label='Total Wages'
  // 									variant='filled'
  // 									fullWidth
  // 									value='Total  ₹27,945'
  // 								/>
  // 							</Grid>
  // 							<Grid item xs={5}>
  // 								<TextField
  // 									variant='filled'
  // 									fullWidth
  // 									label='Daily Wages'
  // 									value='Daily  ₹3,105'
  // 								/>
  // 							</Grid>
  // 						</Grid>
  // 					</Grid>
  // 				</Grid>
  // 				<Grid
  // 					container
  // 					// direction="column"
  // 					style={{ marginTop: 15, marginBottom: 15 }}>
  // 					<Grid item xs={12}>
  // 						<Typography variant='h5' style={{ fontFamily: 'Roboto' }}>
  // 							Work History
  // 						</Typography>
  // 					</Grid>
  // 					<Grid
  // 						container
  // 						item
  // 						xs={12}
  // 						style={{ width: '100%', marginTop: 20 }}>
  // 						<img
  // 							src='/static/images/bettamintImages/searchbar.png'
  // 							alt='Filter'
  // 							style={{ width: '100%' }}
  // 						/>
  // 					</Grid>
  // 					<Grid container item xs={12}>
  // 						<Table>
  // 							<TableHead>
  // 								<TableRow>
  // 									<TableCell
  // 										align='center'
  // 										className={classes.headerCellText}>
  // 										PROJECT NAME
  // 									</TableCell>
  // 									<TableCell
  // 										align='center'
  // 										className={classes.headerCellText}>
  // 										LOCATION
  // 									</TableCell>
  // 									<TableCell
  // 										align='center'
  // 										className={classes.headerCellText}>
  // 										START DATE
  // 									</TableCell>
  // 									<TableCell
  // 										align='center'
  // 										className={classes.headerCellText}>
  // 										END DATE
  // 									</TableCell>
  // 									<TableCell
  // 										align='center'
  // 										className={classes.headerCellText}>
  // 										EARNING
  // 									</TableCell>
  // 								</TableRow>
  // 							</TableHead>
  // 							<TableBody>
  // 								{object.map((val) => (
  // 									<TableRow hover key={val.id}>
  // 										<TableCell
  // 											align='center'
  // 											className={classes.headerCellText}
  // 											width='20%'>
  // 											<Grid
  // 												container
  // 												justify='center'
  // 												item
  // 												xs={12}
  // 												alignItems='center'>
  // 												<Grid item xs={2}>
  // 													<img
  // 														src='/static/images/bettamintImages/workerprofile.png'
  // 														alt='orderProfile'
  // 														style={{
  // 															width: '30px',
  // 															height: '30px',
  // 															marginRight: 4,
  // 														}}
  // 													/>
  // 												</Grid>
  // 												<Grid item xs={10}>
  // 													<Typography
  // 														style={{
  // 															fontSize: 12,
  // 															fontWeight: 'bold',
  // 															fontFamily: 'Roboto',
  // 														}}>
  // 														{val.name}
  // 													</Typography>
  // 												</Grid>
  // 											</Grid>
  // 										</TableCell>
  // 										<TableCell
  // 											align='center'
  // 											className={classes.headerCellText}>
  // 											{val.skillSet}
  // 										</TableCell>
  // 										<TableCell
  // 											align='center'
  // 											className={classes.headerCellText}>
  // 											{val.startDate}
  // 										</TableCell>
  // 										<TableCell
  // 											align='center'
  // 											className={classes.headerCellText}>
  // 											{val.endDate}
  // 										</TableCell>
  // 										<TableCell
  // 											align='center'
  // 											className={classes.headerCellText}>
  // 											{val.earning}
  // 										</TableCell>
  // 									</TableRow>
  // 								))}
  // 							</TableBody>
  // 						</Table>
  // 					</Grid>
  // 				</Grid>
  // 			</>
  // 		)}{' '}
  // 	</Grid>
  // );

  const redirectToDetailPage = (worker) => {
    dispatch(getWorkerAttendance(worker.jobId, worker.workerId));
    dispatch(selectWorker(worker));
    dispatch(showPayment(true));

    navigate('/app/attendance/detail');
  };

  //! FILTERS METHODS

  const handleFilters = (e) => {
    const { value } = e.target;

    if (value !== 0) {
      setFilters({ ...filters, sortBy: value });
      if (
        attendanceList?.filter((job) => job.sKillName === value).length === 0 &&
        value != 0
      )
        return setFilteredArray(null);

      setFilteredArray(
        attendanceList?.filter((job) => job.sKillName === value),
      );
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;

    setFilters({ ...filters, search: value });

    if (value) {
      const data = filteredArray.length ? filteredArray : attendanceList;
      let newFilter = data.filter((item) => {
        return Object.values(item)
          .join('')
          .toLowerCase()
          .includes(value.toLowerCase());
      });
      setFilteredArray(newFilter);
    }

    if ((!value && filteredArray.length === 0) || filteredArray === null) {
      // if (filters.sortBy) {
      // 	handleFilters({ target: { value: filters.sortBy } });
      // }
      // if (dateRange[1] !== null) {
      // 	return handleDateRange(dateRange);
      // }
      return setFilteredArray([]);
    }
  };
  const handleDateRange = (update) => {
    let [start, end] = update;
    setDateRange(update);

    if (start && !end) {
      const dynamicJobList =
        filteredArray?.length > 0 ? filteredArray : attendanceList;

      setFilteredArray(
        dynamicJobList.filter((job) => {
          const startDateOnly = new Date(job.startDate).setHours(0, 0, 0, 0);
          // const endDateOnly = new Date(job.endDate).setHours(0, 0, 0, 0);

          return startDateOnly === start.getTime();
        }),
      );

      if (filteredArray?.length === 0) {
        setFilteredArray(null);
      }
    }

    if (end) {
      const dynamicJobList =
        filteredArray?.length > 0 ? filteredArray : attendanceList;

      setFilteredArray(
        dynamicJobList.filter((job) => {
          const startDateOnly = new Date(job.startDate).setHours(0, 0, 0, 0);
          // const endDateOnly = new Date(job.endDate).setHours(0, 0, 0, 0);

          return (
            startDateOnly >= start.getTime() && startDateOnly <= end.getTime()
          );
        }),
      );

      if (filteredArray?.length === 0) {
        setFilteredArray(null);
      }
    } else {
      if (!start && !end) {
        setFilteredArray([]);
      }
      // let isSortted = parseInt(filters.sortBy);
      // if (isSortted !== 0)
      // 	return setFilteredArray(
      // 		attendanceList.filter(job => job.skillId == isSortted)
      // 	);
      // setFilteredArray(attendanceList);
    }
  };
  const handleDateRangeHistory = (update) => {
    setDateRangeHistory(update);
    let [start, end] = update;
    if (end) {
      if (startDateHistory) {
        dispatch(
          getPaymentHistory(
            currentProjectHistory?.projectId,

            moment.utc(start).format(),
            moment.utc(end).format(),
          ),
        );
      } else {
        dispatch(
          getPaymentHistory(
            currentProjectHistory?.projectId,
            '',
            '',
            // moment.utc(startDateHistory).format(),
            // moment.utc(endDateHistory).format()
          ),
        );
      }
    }
  };

  const handleCheckbox = (workerId, jobId, isChecked) => {
    if (filteredArray.length) {
      let data = filteredArray.map((item) => {
        if (item.workerId === workerId && item.jobId === jobId) {
          return {
            ...item,
            isChecked,
          };
        }
        return item;
      });
      setAttendanceList([...data]);
      return;
    }
    // if (!isChecked) {
    let data = attendanceList.map((item) => {
      if (item.workerId === workerId && item.jobId === jobId) {
        return {
          ...item,
          isChecked,
        };
      }
      return item;
    });
    setAttendanceList([...data]);
  };
  const handleAllCheckboxChange = (isChecked) => {
    if (filteredArray.length) {
      if (isChecked) {
        setAttendanceList(
          filteredArray.map((item) => ({
            ...item,
            isChecked: true,
          })),
        );

        // setCheckList(newData);
      } else {
        setAttendanceList(
          filteredArray.map((item) => ({
            ...item,
            isChecked: false,
          })),
        );
      }
      return;
    }

    if (isChecked) {
      setAttendanceList(
        attendanceList.map((item) => ({
          ...item,
          isChecked: true,
        })),
      );

      // setCheckList(newData);
    } else {
      setAttendanceList(
        attendanceList.map((item) => ({
          ...item,
          isChecked: false,
        })),
      );
    }
  };
  // const checkList = () => {
  // 	let temp = checkList.map((item) => {
  // 		if (item === workerId) {
  // 			return true;
  // 		} else {
  // 			return false;
  // 		}
  // 	});
  // };

  const handleOfflinePayment = async () => {
    let myCheckList = [];

    for (let item of attendanceList) {
      if (item.isChecked) {
        myCheckList.push(item);
      }
    }
    await myCheckList.map(async (item, index) => {
      let resp = await dispatch(sendPayment(item.jobId, item.workerId));
      if (resp.status === 200) {
        // if ( index === 0 ) {
        // 	window.open( resp?.data?.paymentUrl, "_blank" );
        // }
        dispatch(getAttendance(currentProject?.projectId));

        toast.success('Amount Send For Process!');
      } else {
        toast.error(resp?.data?.error);
      }
    });
  };
  const handleOnlinePayment = async () => {
    let myCheckList = [];

    for (let item of attendanceList) {
      if (item.isChecked) {
        myCheckList.push(item);
      }
    }
    await myCheckList.map(async (item, index) => {
      let resp = await dispatch(
        sendPayment(item.jobId, item.workerId, 'Online'),
      );
      if (resp.status === 200) {
        // if ( index === 0 && resp?.data?.paymentUrl !== "" ) {
        // 	window.open( resp?.data?.paymentUrl, "_blank" );
        // }

        toast.success('Amount Send For Process!');
        dispatch(getAttendance(currentProject?.projectId));

        setPaymentHitoryModal(false);
      } else {
        toast.error(resp?.data?.error);
        setPaymentHitoryModal(false);
      }
    });
  };

  const openPaymentHistory = () => {
    setIsPaymentHistoryOpened(true);
  };

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      // maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      borderRadius: '10px',
    },
  }))(Tooltip);

  const handleLabourContractor = (val) => {
    setLabourContractor(val);
    dispatch(getAttendance(currentProject?.projectId, val?.userId));
  };

  const openPaymentHistoryModalSummary = (row) => {
    setIsInvoiceVisible(true);
    setPaymenetSummarySelectedRow(row);
  };

  const openPayOnlineModal = () => {
    if (attendanceList?.filter((item) => item.isChecked)?.length === 0) {
      return alert('Please select at least one worker');
    }

    setPaymentHitoryModal(true);
    dispatch(
      getPaymentSummary(
        currentProject?.projectId,

        attendanceList
          ?.filter((item) => item.isChecked)
          .map((item) => item.workerId),
      ),
    );
    let total = 0;
    attendanceList?.filter((item) => {
      if (item.isChecked) {
        total += item.dueAmount;
        return item;
      }
    });
    setTotalSelectedWorkerAmount(total);
  };
  const handleChangePage = (event, newPage) => {
    setPaginationData({ ...paginationData, page: newPage });

    dispatch(
      getAttendance(
        currentProject.projectId,
        LabourContractor?.userId,
        false,
        newPage + 1,
      ),
    );
  };

  const renderPaymentsList = () => (
    <Grid container>
      {
        <>
          <Dialog
            fullWidth={'lg'}
            maxWidth={'lg'}
            open={isPaymentHistoryOpened}
            onClose={() => setIsPaymentHistoryOpened(false)}
          >
            <DialogTitle onClose={() => setIsPaymentHistoryOpened(false)}>
              Payrol History - Please select project name and date range to see
              the Payrol history
            </DialogTitle>
            <DialogContent dividers style={{ height: '50vh' }}>
              <Grid
                container
                justify="space-between"
                alignItems="center"
                style={{ marginBottom: '1rem' }}
              >
                <Grid
                  item
                  container
                  xs={6}
                  justify="flex-start"
                  alignItems="center"
                >
                  <Grid item>Choose Project |</Grid>
                  <Grid item>
                    <FilterProjectComponent
                      projectClassificationList={projectClassificationList}
                      src={
                        !classifiedProjectHistory[0]?.url
                          ? defaultProjectImage
                          : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProjectHistory[0]?.url}`
                      }
                      value={currentProjectHistory}
                      onChange={classifyProjectHistory}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  xs={5}
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item style={{ paddingRight: '10px' }}>
                    Select Date Range |{' '}
                  </Grid>
                  <Grid
                    item
                    style={{
                      position: 'relative',
                    }}
                    xs={8}
                  >
                    <DatePicker
                      dateFormat="MMMM d, yyyy"
                      selectsRange={true}
                      startDate={startDateHistory}
                      endDate={endDateHistory}
                      onChange={(update) => {
                        handleDateRangeHistory(update);
                      }}
                      isClearable={true}
                      className="font12 date-picker "
                    />
                    {!startDateHistory && (
                      <EventNoteIcon
                        style={{
                          position: 'absolute',
                          right: '5px',
                          top: '7px',
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {historyLoading ? (
                <Grid container justify="center" alignItems="center">
                  <Grid item>
                    <CircularProgress style={{ color: '#B2FF59' }} />
                  </Grid>
                </Grid>
              ) : (
                <TableContainer component={Paper}>
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Worker Name</TableCell>
                        <TableCell>Job Name</TableCell>
                        <TableCell>Paid Date</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="left">Amount</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {history?.length > 0 ? (
                        history?.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left" style={{ fontWeight: 700 }}>
                              {row.workerName}
                            </TableCell>

                            <TableCell align="left">{row.jobName}</TableCell>
                            <TableCell align="left">{row.paidDate}</TableCell>
                            <TableCell align="left">
                              <Chip
                                label={row.transactionStatusId}
                                icon={<AccessTimeIcon />}
                              ></Chip>
                            </TableCell>
                            <TableCell align="left">₹{row.amount}</TableCell>
                            <TableCell align="left">
                              <Button
                                fullWidth
                                className={classes.buttonPurpleOpacity}
                                style={{ textTransform: 'capitalize' }}
                                size="small"
                                onClick={() =>
                                  openPaymentHistoryModalSummary(row)
                                }
                              >
                                {' '}
                                View Invoice
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <Grid>No Seach Found!</Grid>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </DialogContent>
          </Dialog>
          <Dialog
            fullWidth={'md'}
            maxWidth={'md'}
            open={isInvoiceVisible}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            onClose={() => setIsInvoiceVisible(false)}
          >
            {/* <DialogTitle
							onClose={() => setIsInvoiceVisible(false)}
							id='scroll-dialog-title'>
							Bettamint Invoice
						</DialogTitle> */}

            <DialogContent
              // dividers

              style={{ padding: '16px 0 0 0' }}
              id="scroll-dialog-description"
            >
              <Grid container xs={12} style={{ padding: '15px' }}>
                {/* Header */}
                <Grid
                  item
                  container
                  justify="space-between"
                  style={{ padding: '0 15px' }}
                >
                  <Grid item>
                    <img src={logo} alt="Logo" />{' '}
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      align="right"
                      variant="subtitle1"
                    >
                      WeWork Galaxy, 43 Residency Rd,
                    </Typography>{' '}
                    <Typography
                      variant="subtitle1"
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      Bengaluru, Karnataka 560025, India
                    </Typography>{' '}
                    <Typography
                      align="right"
                      variant="subtitle1"
                      style={{ fontWeight: 700 }}
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      Bettamint India Private Limited
                    </Typography>{' '}
                    <Typography
                      align="right"
                      variant="subtitle1"
                      style={{ fontWeight: 700 }}
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      GSTIN: 29AALCB0969P1ZT
                    </Typography>{' '}
                  </Grid>
                </Grid>
                {/* Heading */}
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="center"
                  style={{
                    // marginBottom: '1.5rem',
                    padding: '3rem 15px 2rem 15px',
                  }}
                >
                  <Grid item>
                    <Typography
                      variant="h4"
                      className={classes.typographyCommonStyle}
                    >
                      K2K Infrastucture (I) Pvt Ldt
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h2"
                      className={`${classes.typographyCommonStyle} ${classes.greenFontColor}`}
                    >
                      INVOICE
                    </Typography>
                  </Grid>
                </Grid>
                {/* SubHeading */}
                <Grid
                  item
                  container
                  justify="space-between"
                  xs={12}
                  style={{ padding: '0 15px' }}
                >
                  <Grid container xs={5}>
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          Address:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="subtitle1"
                          // style={{ paddingLeft: '10px' }}
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                        >
                          Prestige Copper Arch, K2K Infrastructure Private
                          Limited #83, 4th Floor, Infantry Rd, Bengaluru,
                          Karnataka 560001, India
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          GSTIN:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="subtitle1"
                          // style={{ paddingLeft: '10px' }}
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                        >
                          123 456 789 10
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          Email:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="subtitle1"
                          // style={{ paddingLeft: '10px' }}
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                        >
                          sales@k2k.com
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container justify="space-between">
                      <Grid item>
                        <Typography
                          variant="subtitle1"
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                          style={{
                            fontWeight: 600,
                          }}
                        >
                          Contact:
                        </Typography>
                      </Grid>
                      <Grid item xs={10}>
                        <Typography
                          variant="subtitle1"
                          // style={{ paddingLeft: '10px' }}
                          className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                        >
                          +91 80 2500 1432
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      align="right"
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ fontWeight: 700 }}
                    >
                      Invoice # 10739
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      Issue Date: 17 Mar, 2023
                    </Typography>
                  </Grid>
                </Grid>
                {/* Invoice Sumary */}
                <Grid
                  item
                  container
                  style={{
                    marginTop: '4rem',
                    padding: '0 15px',
                  }}
                >
                  <Typography
                    variant="h4"
                    className={`${classes.greenFontColor} ${classes.typographyCommonStyle}`}
                  >
                    Invoice Summary
                  </Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  justify="space-between"
                  style={{
                    marginTop: '2rem',
                    // paddingRight: '19px',
                    padding: '0 15px',
                  }}
                >
                  <Typography
                    className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                  >
                    Description
                  </Typography>
                  <Typography
                    className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    style={{ paddingRight: '22px' }}
                  >
                    Total
                  </Typography>
                </Grid>
                {/* Divider */}
                <Grid
                  item
                  xs={12}
                  style={{
                    borderTop: '1px solid #4D6068',
                    margin: '15px 0',
                  }}
                >
                  {' '}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                  style={{
                    padding: '0 15px',
                    marginBottom: '3rem',
                  }}
                >
                  <Grid item>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      {/* <span style={{ fontWeight: 700, fontSize: '14px' }}>
												5
											</span>{' '} */}
                      Wage Fees
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      Transaction Fees
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      PF
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      ESIC
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      <span style={{ fontWeight: 700, fontSize: '14px' }}>
                        0%
                      </span>{' '}
                      GST
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ {paymentSummarySelectedRow?.amount?.toLocaleString()}
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ 0
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ 0
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ 0
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ 0
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                style={{ backgroundColor: '#E0EBD2', padding: '2rem 15px' }}
              >
                <Grid
                  item
                  container
                  justify="space-between"
                  style={{ padding: '0 15px' }}
                >
                  <Grid item>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      Paid On
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      Total Paid
                    </Typography>
                  </Grid>
                </Grid>
                {/* Divider */}
                <Grid
                  item
                  xs={12}
                  style={{
                    borderTop: '1px solid #4D6068',
                    margin: '15px 0',
                  }}
                >
                  {' '}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  style={{ padding: '15px 12px' }}
                  justify="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="h3"
                      className={`${classes.typographyCommonStyle} `}
                      style={{ fontWeight: 700 }}
                    >
                      {/* 17 Mar, 2023 */}
                      {setPaymenetSummarySelectedRow?.paidDate
                        ? moment(
                            setPaymenetSummarySelectedRow?.paidDate,
                          ).format('LL')
                        : 'N/A'}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      className={`${classes.typographyCommonStyle} ${classes.greenFontColor}`}
                      style={{ fontWeight: 700 }}
                    >
                      ₹{paymentSummarySelectedRow?.amount?.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    borderTop: '1px solid #4D6068',
                    margin: '15px 0',
                  }}
                >
                  {' '}
                </Grid>

                {/* <Grid item style={{ padding: '20px 12px' }}>
									<Grid item>
										<Typography
											variant='subtitle1'
											style={{ fontWeight: '600' }}
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											Bettamint Technologies Pte Ltd
										</Typography>
										<Typography
											variant='subtitle1'
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											160 Robinson Road, #14-04
										</Typography>
										<Typography
											variant='subtitle1'
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											068914 Singapore, SG
										</Typography>
										<Typography
											variant='subtitle1'
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											VAT ID: 202220701E
										</Typography>
									</Grid>
								</Grid> */}
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="flex-end"
                  xs={12}
                  style={{ padding: '4rem 12px 2rem 12px' }}
                >
                  <Grid item>
                    <img src={logoText} alt="Bettamint Text Logo" />
                    <Typography
                      variant="subtitle1"
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}
                    >
                      Terms & Conditions applied.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}
                    >
                      customersupport@bettamint.com
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Dialog
            fullWidth={'md'}
            maxWidth={'md'}
            open={paymentHitoryModal}
            scroll="body"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            onClose={() => setPaymentHitoryModal(false)}
          >
            {/* <DialogTitle
							onClose={() => setIsInvoiceVisible(false)}
							id='scroll-dialog-title'>
							Bettamint Invoice
						</DialogTitle> */}

            <DialogContent
              // dividers

              style={{ padding: '16px 0 0 0' }}
              id="scroll-dialog-description"
            >
              <Grid container xs={12} style={{ padding: '15px' }}>
                {/* Invoice Sumary */}
                <Grid
                  item
                  container
                  style={{
                    // marginTop: "4rem",
                    padding: '0 15px',
                  }}
                >
                  <Typography
                    variant="h4"
                    className={`${classes.greenFontColor} ${classes.typographyCommonStyle}`}
                  >
                    Payroll Summary
                  </Typography>
                </Grid>
                <Grid
                  container
                  xs={12}
                  justify="space-between"
                  style={{
                    marginTop: '2rem',
                    // paddingRight: '19px',
                    padding: '0 15px',
                  }}
                >
                  <Typography
                    className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                  >
                    Description
                  </Typography>
                  <Typography
                    className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    style={{ paddingRight: '22px' }}
                  >
                    Total
                  </Typography>
                </Grid>
                {/* Divider */}
                <Grid
                  item
                  xs={12}
                  style={{
                    borderTop: '1px solid #4D6068',
                    margin: '15px 0',
                  }}
                >
                  {' '}
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  justify="space-between"
                  alignItems="center"
                  style={{
                    padding: '0 15px',
                    marginBottom: '3rem',
                  }}
                >
                  <Grid item>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      {/* <span style={{ fontWeight: 700, fontSize: '14px' }}>
												5
											</span>{' '} */}
                      Wage Fees
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      Transaction Fees
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      PF
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      ESIC
                    </Typography>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px' }}
                    >
                      <span style={{ fontWeight: 700, fontSize: '14px' }}>
                        {/* 18% */}
                        0%
                      </span>{' '}
                      GST
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      {/* ₹ 4,000 */}₹{' '}
                      {totalSelectedWorkerAmount.toLocaleString()}
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ {paymentSummary?.transactionFees?.toLocaleString()}
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ 0
                    </Typography>
                    <Typography
                      className={`${classes.greenFontColor} ${classes.textFontSize}`}
                      style={{ marginBottom: '5px', fontWeight: 700 }}
                    >
                      ₹ 0
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                xs={12}
                style={{ backgroundColor: '#E0EBD2', padding: '2rem 15px' }}
              >
                <Grid
                  item
                  container
                  justify="space-between"
                  style={{ padding: '0 15px' }}
                >
                  <Grid item>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      Wallet Amount
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize}`}
                    >
                      Total Due
                    </Typography>
                  </Grid>
                </Grid>
                {/* Divider */}
                <Grid
                  item
                  xs={12}
                  style={{
                    borderTop: '1px solid #4D6068',
                    margin: '15px 0',
                  }}
                >
                  {' '}
                </Grid>

                <Grid
                  item
                  container
                  xs={12}
                  style={{ padding: '15px 12px' }}
                  justify="space-between"
                >
                  <Grid item>
                    <Typography
                      variant="h3"
                      className={`${classes.typographyCommonStyle} ${classes.greenFontColor}`}
                      style={{ fontWeight: 700 }}
                    >
                      ₹ 965,000
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="h3"
                      className={`${classes.typographyCommonStyle} ${classes.greenFontColor}`}
                      style={{ fontWeight: 700 }}
                    >
                      ₹ {paymentSummary?.netAmount?.toLocaleString()}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    borderTop: '1px solid #4D6068',
                    margin: '15px 0',
                  }}
                >
                  {' '}
                </Grid>
                <Grid container xs={12} justify="space-between">
                  <Button
                    color="primary"
                    style={{ textTransform: 'capitalize', border: '1px solid' }}
                    onClick={() => setOpenBanner(true)}
                  >
                    Recharge Wallet
                  </Button>{' '}
                  <Button
                    onClick={handleOnlinePayment}
                    className={classes.buttonGreen}
                    style={{
                      textTransform: 'capitalize',
                      marginLeft: '2rem',
                      fontWeight: 900,
                      padding: '10px 1.5rem',
                    }}
                  >
                    Process Payment{' '}
                  </Button>
                </Grid>

                {/* <Grid item style={{ padding: '20px 12px' }}>
									<Grid item>
										<Typography
											variant='subtitle1'
											style={{ fontWeight: '600' }}
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											Bettamint Technologies Pte Ltd
										</Typography>
										<Typography
											variant='subtitle1'
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											160 Robinson Road, #14-04
										</Typography>
										<Typography
											variant='subtitle1'
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											068914 Singapore, SG
										</Typography>
										<Typography
											variant='subtitle1'
											className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}>
											VAT ID: 202220701E
										</Typography>
									</Grid>
								</Grid> */}
                <Grid
                  item
                  container
                  justify="space-between"
                  alignItems="flex-end"
                  xs={12}
                  style={{ padding: '4rem 12px 2rem 12px' }}
                >
                  <Grid item>
                    <img src={logoText} alt="Bettamint Text Logo" />
                    <Typography
                      variant="subtitle1"
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}
                    >
                      Terms & Conditions applied.
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography
                      variant="subtitle1"
                      className={`${classes.typographyCommonStyle} ${classes.textFontSize} `}
                    >
                      customersupport@bettamint.com
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </Dialog>

          <Grid
            container
            item
            xs={12}
            justify="space-between"
            style={{ marginBottom: 15 }}
          >
            <Grid
              container
              item
              xs={12}
              md={4}
              justify="flex-start"
              alignItems="center"
            >
              <FilterProjectComponent
                projectClassificationList={projectClassificationList}
                src={
                  !classifiedProject[0]?.url
                    ? defaultProjectImage
                    : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                }
                value={currentProject}
                onChange={classifyProject}
              />
            </Grid>

            <Grid item md={3} xs={12}>
              {userData?.user.leadTypeId !== 'LabourContractor' && (
                <Grid
                  item
                  // md={3}
                  xs={12}
                  style={{ paddingRight: '10px' }}
                  className={classes.marginBottom}
                >
                  <FilterByLabourContractorComponent
                    value={LabourContractor}
                    onChange={handleLabourContractor}
                    setInitialUser={(user) => setLabourContractor(user)}
                    currentProject={classifiedProject[0]}
                  />
                </Grid>
              )}
            </Grid>

            <Grid
              container
              justify="flex-end"
              item
              xs={12}
              md={4}
              alignItems="center"
            >
              <Button
                className={` ${classes.headerButton} ${classes.buttonFullSize} ${classes.buttonGreen}`}
                variant="outlined"
                style={{
                  textTransform: 'capitalize',
                }}
                onClick={openPayOnlineModal}
                disabled={access.isViewMode}
              >
                Pay Now{' '}
                {
                  attendanceList?.filter((item) => {
                    if (item.isChecked) {
                      return item;
                    }
                  })?.length
                }
              </Button>
              {/* <Button
                className={` ${classes.headerButton} ${classes.buttonFullSize}`}
                variant="outlined"
                onClick={handleOfflinePayment}
                style={{ textTransform: "capitalize" }}
              >
                Pay Offline{" "}
                {
                  attendanceList?.filter((item) => {
                    if (item.isChecked) {
                      return item;
                    }
                  })?.length
                }
              </Button> */}
              <Button
                className={` ${classes.headerButton} ${classes.buttonFullSize} ${classes.buttonPurpleOpacity}`}
                onClick={openPaymentHistory}
                variant="outlined"
                style={{ textTransform: 'capitalize' }}
                startIcon={<AccessTimeIcon />}
              >
                Payroll History
              </Button>
              {/* <Grid item ><Divider orientation="vertical" /></Grid> */}

              <HtmlTooltip
                title={
                  <Grid
                    style={{
                      fontWeight: '400',
                      padding: '5 10px',
                      // borderRadius: '10px',
                      // border: '1px solid #ddd',
                    }}
                  >
                    Attendance is validated via{' '}
                    <i style={{ fontWeight: 600 }}>two-factor</i> authentication
                    i.e. Worker Check-In & Geolocation Tracking during work
                    hours.
                  </Grid>
                }
              >
                <Button
                  className={` ${classes.headerButton} ${classes.buttonFullSize}`}
                  variant="outlined"
                  style={{
                    backgroundColor: '#FDD9D9',
                    color: 'red',
                    // padding: "5px"
                    // border: "none"
                  }}
                  // startIcon={ <ErrorOutlineIcon /> }
                  title=""
                >
                  <ErrorOutlineIcon style={{ fontSize: '20px' }} />
                  {/* NOTE! */}
                </Button>
              </HtmlTooltip>
            </Grid>
          </Grid>

          <Grid
            xs={12}
            container
            // spacing={3}
            alignItems="center"
            className="input-container-nav"
            justify="space-around"
          >
            {/* container */}
            <Grid item md={9} xs={12} container justify="flex-start">
              {/* //! 1.SortInput */}
              <Grid
                item
                md={4}
                xs={12}
                container
                alignItems="center"
                justify="space-between"
                // className='padding5'

                style={{ padding: '2px' }}
              >
                <Grid item>
                  {' '}
                  <small>Skills:</small>
                </Grid>
                <Grid item xs={10}>
                  <SortSelection
                    initialValue="Skills"
                    value={filters.sortBy}
                    handleFilters={handleFilters}
                    data={skillsList?.map((skill) => ({
                      value: skill.name,
                      label: skill.name,
                    }))}
                  />
                </Grid>
              </Grid>
              {/* //! 2.DatePickerInput */}
              <Grid
                item
                md={4}
                xs={12}
                container
                justify="space-between"
                alignItems="center"
                className={classes.marginRight}
              >
                {/* <div style={{ width: '100%', position: 'relative' }}> */}
                <Grid item className={classes.marginLeft}>
                  <small>Date:</small>
                </Grid>
                <Grid
                  item
                  xs={10}
                  style={{
                    position: 'relative',
                  }}
                >
                  <DatePicker
                    dateFormat="MMMM d, yyyy"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      handleDateRange(update);
                    }}
                    isClearable={true}
                    className="font12 date-picker "
                  />
                  {!startDate && (
                    <EventNoteIcon
                      style={{ position: 'absolute', right: '5px', top: '7px' }}
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            {/* //! 3.SearchInput */}
            <Grid
              item
              md={3}
              xs={12}
              container
              justify="space-between"
              alignItems="center"
              style={{ padding: '2px' }}
            >
              <Grid item xs={2}>
                <small>Search:</small>
              </Grid>
              <Grid item md={10} xs={10} style={{ position: 'relative' }}>
                <SearchInput
                  search={filters.search}
                  handleSearch={handleSearch}
                  // className='font12'
                />
              </Grid>
            </Grid>
            {/* <Grid xs={12} style={{ marginTop: '10px' }}>
							Selected Workers:
							<Badge
								style={{
									marginLeft: '15px',
								}}
								classes={{ badge: classes.customBadge }}
								// color='primary'
								badgeContent={checkList.length}
								showZero></Badge>
						</Grid> */}
          </Grid>
          {loading || fetchLoading ? (
            <div
              style={{
                textAlign: 'center',
                overflow: 'hidden',
                width: '100%',
              }}
            >
              <CircularProgress style={{ color: '#81B734' }} />
            </div>
          ) : (
            <Grid container item xs={12} style={{ overflowX: 'scroll' }}>
              {offlinePaymentLoading && (
                <div
                  style={{
                    textAlign: 'center',
                    overflow: 'hidden',
                    width: '100%',
                  }}
                >
                  <CircularProgress style={{ color: '#81B734' }} />
                </div>
              )}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      <Checkbox
                        onChange={(e) =>
                          handleAllCheckboxChange(e.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      WORKER NAME
                    </TableCell>
                    {/* <TableCell
											align='center'
											className={classes.headerCellText1}>
											SKILL SET
										</TableCell> */}
                    {/* <TableCell
											align='center'
											className={classes.headerCellText1}>
											PROJECT
										</TableCell> */}
                    {/* <TableCell
											align='center'
											className={classes.headerCellText1}>
											DESCRIPTION
										</TableCell> */}
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      TOTAL AMOUNT
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      DUE AMOUNT
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      ISSUED AMOUNT
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      PROCESS AMOUNT
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      STATUS
                    </TableCell>
                    <TableCell
                      align="center"
                      className={classes.headerCellText1}
                    >
                      ACTIONS
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredArray?.length ? (
                    filteredArray?.map((val, index) => (
                      <TableRow hover key={index}>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Checkbox
                            // checked={}
                            onChange={(e) =>
                              handleCheckbox(
                                val.workerId,
                                val.jobId,
                                e.target.checked,
                              )
                            }
                          />
                        </TableCell>

                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                          width="20%"
                        >
                          <Grid container justify="center" alignItems="center">
                            {/* <Grid>
												<img
													src='/static/images/bettamintImages/workerprofile.png'
													alt='orderProfile'
													style={{
														width: '30px',
														height: '30px',
														marginRight: 4,
													}}
												/>
											</Grid> */}
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto',
                                }}
                              >
                                {val.workerName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.sKillName}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.projectName}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.jobDescription ?? 'N/A'}
												</TableCell> */}
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.totalAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.dueAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.issuedAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.inProcessAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.workerType ?? 'N/A'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                          width="15%"
                        >
                          <Button
                            className={classes.buttonPurpleOpacity}
                            style={{
                              fontSize: 12,
                              padding: '10px',
                              textTransform: 'capitalize',
                              borderRadius: '10px',
                            }}
                            onClick={() => redirectToDetailPage(val)}

                            // onClick={() => {
                            // 	setEdit(true);
                            // 	setShowCard(false);
                            // 	// setCreateNewProject(false);
                            // }}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : filters.search !== '' || filteredArray === null ? (
                    <TableRow>No Search Found!</TableRow>
                  ) : attendanceList?.attendances?.length === 0 ? (
                    <TableRow>No Search Found!</TableRow>
                  ) : (
                    attendanceList?.map((val, index) => (
                      <TableRow hover key={index}>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          <Checkbox
                            checked={val.isChecked}
                            onChange={(e) =>
                              handleCheckbox(
                                val.workerId,
                                val.jobId,
                                e.target.checked,
                              )
                            }
                          />
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                          width="15%"
                        >
                          <Grid container justify="center" alignItems="center">
                            {/* <Grid>
												<img
													src='/static/images/bettamintImages/workerprofile.png'
													alt='orderProfile'
													style={{
														width: '30px',
														height: '30px',
														marginRight: 4,
													}}
												/>
											</Grid> */}
                            <Grid>
                              <Typography
                                style={{
                                  fontSize: 12,
                                  fontWeight: 'bold',
                                  fontFamily: 'Roboto',
                                }}
                              >
                                {val.workerName}
                              </Typography>
                            </Grid>
                          </Grid>
                        </TableCell>
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.sKillName}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.projectName}
												</TableCell> */}
                        {/* <TableCell
													align='center'
													className={classes.headerCellText}>
													{val.jobDescription ?? 'N/A'}
												</TableCell> */}
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.totalAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.dueAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.issuedAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          ₹{val.inProcessAmount.toLocaleString()}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                        >
                          {val.workerType ?? 'N/A'}
                        </TableCell>
                        <TableCell
                          align="center"
                          className={classes.headerCellText}
                          width="15%"
                        >
                          <Button
                            className={classes.buttonPurpleOpacity}
                            style={{
                              fontSize: 12,
                              padding: '10px',
                              textTransform: 'capitalize',
                              borderRadius: '10px',
                            }}
                            onClick={() => redirectToDetailPage(val)}

                            // onClick={() => {
                            // 	setEdit(true);
                            // 	setShowCard(false);
                            // 	// setCreateNewProject(false);
                            // }}
                          >
                            View Details
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
              <Grid container item justify="flex-end" alignItems="center">
                <TablePagination
                  component="div"
                  count={listOfAttendance?.totalCount}
                  rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                  rowsPerPage={paginationData?.rowsPerPage}
                  page={paginationData?.page}
                  colSpan={3}
                  onChangePage={handleChangePage}
                />
              </Grid>
              <Grid
                container
                alignItems="center"
                justify="center"
                style={{ padding: '1rem 0' }}
              >
                <Grid
                  xs={11}
                  item
                  container
                  alignItems="center"
                  justify="flex-end"
                >
                  <Typography
                    variant="subtitle1"
                    style={{
                      fontSize: '11px',
                      marginRight: '2px',
                      fontWeight: '400',
                    }}
                  >
                    Powered By:
                  </Typography>
                  <img src={BettaPayLogo} alt="BettaPay Logo" />
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      }
    </Grid>
  );

  let cardData = [
    {
      title: 'Current Wallet Amount',
      count: `10,950,000`,
      subtitle: 'Rupee',
      icon: <AccountBalanceWalletIcon style={{ color: '#B2BEC7' }} />,
      variations: '-23%',
      action: () => {},
    },
    {
      title: 'Current Due Amount',
      count: `53,060,000`,
      subtitle: 'Rupee',
      icon: <img src={vector} alt="defect" />,
      variations: '+10%',
      action: () => {},
    },
    {
      title: 'Recharge Your Wallet',
      count: `42,110,000`,
      subtitle: 'Difference In Amount',
      icon: <AccountBalanceWalletIcon style={{ color: '#B2BEC7' }} />,
      variations: '+10%',
      action: () => setOpenBanner(true),
    },
  ];

  return (
    <Page className={classes.root} id="zoom-level" title="Payments">
      <Container maxWidth={false}>
        <Grid container>
          <RechargeBanner
            open={openBanner}
            handleSubmit={() => setOpenBanner(false)}
          />
          {cardData.map((card) => (
            <PaymentCardTiles
              isLoading={false}
              icon={card.icon}
              title={card.title}
              count={card.count}
              subtitle={card.subtitle}
              filter={card.filter}
              variations={card.variations}
              action={card.action}
              access={access}
              classes={classes}
            />
          ))}
        </Grid>
        <PayrollModalComponent
          isOpen={payrollModal}
          closeModal={() => setPayrollModal(false)}
        />
        <Grid
          container
          style={{
            background: 'white',
            padding: '1em',
            borderRadius: '10px',
          }}
        >
          {!edit && renderPaymentsList()}
          {/* {edit && renderViewPaymentDetails()} */}
          <Toaster />
        </Grid>
      </Container>
    </Page>
  );
}

export default Payments;
