import React, { useEffect, useState } from 'react';
import { VictoryPie, VictoryLabel } from 'victory';

import {
  Avatar,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import DatePicker from 'react-datepicker';

import defaultProjectImage from '../../../assests/Projects Icon.png';

import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectsClassificationList,
  projectReducer,
} from 'src/redux/projects';
import { Autocomplete } from '@material-ui/lab';

const data = [
  { label: 'Certified', y: 100 },
  { label: 'Billed', y: 95 },
  { label: 'Debited', y: 95 },
  { label: 'Payable', y: 95 },
  { label: 'Paid', y: 80 },
];

const colors = ['#81B734', '#A179F2', '#4D79F6', '#E17646', '#81B734'];

export default function BillingInformationChart(props) {
  const { classes } = props;

  const [currentProject, setCurrentProject] = useState(null);
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const [classifiedProject, setClassifiedProject] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [startDate, endDate] = dateRange;
  let dispatch = useDispatch();

  const { projectClassificationList } = useSelector(projectReducer);

  // useEffect(() => {
  //   if (projectClassificationList.length === 0) {
  //     dispatch(getProjectsClassificationList());
  //   }
  // }, [projectClassificationList]); // eslint-disable-line

  const handleProjectChange = (project) => {
    setClassifiedProject([project]);
    setCurrentProject(project);
  };

  return (
    <Grid item container className={classes.graphContainer}>
      <Grid item container className={classes.graph}>
        <Grid item container style={{ height: '100%' }}>
          <Grid item container className={classes.graphTitle}>
            <Typography variant={isSmallScreen ? 'h6' : 'h5'}>
              Contractor Production Rate
            </Typography>
            <Button className={classes.purpleOpacity}>View Details</Button>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            className={classes.fontSmall}
            style={{ padding: '0px 15px' }}
          >
            <Grid item xs={6}>
              <Grid container alignItems="center">
                <Grid item style={{ paddingRight: '10px' }}>
                  <Avatar
                    alt="Project Image"
                    style={{
                      width: '35px',
                      height: '35px',
                      marginLeft: '.5rem',
                    }}
                    src={
                      !classifiedProject[0]?.url
                        ? defaultProjectImage
                        : `${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_SANDBOX}${classifiedProject[0]?.url}`
                    }
                  />
                </Grid>
                <Grid item>
                  <Autocomplete
                    autoSelect
                    size="small"
                    options={projectClassificationList}
                    getOptionLabel={(option) => option.name}
                    style={{ minWidth: '150px' }}
                    value={currentProject}
                    onChange={(event, newValue) => {
                      handleProjectChange(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Select Project"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              container
              alignItems="center"
              justifyContent="space-between"
              xs={6}
            >
              <Typography style={{ fontSize: '0.9rem' }}>Filters:</Typography>
              <Grid style={{ width: '80%' }}>
                <DatePicker
                  dateFormat="dd MMM, yyyy"
                  showIcon
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="center"
            style={{ padding: '0px 20px' }}
          >
            <Grid style={{ width: '70%' }}>
              <svg viewBox="0 0 400 400" width="100%" height="100%">
                {data.map((d, index) => (
                  <>
                    <VictoryPie
                      key={index}
                      standalone={false}
                      data={[d, { y: 100 - d.y }]}
                      innerRadius={120 - index * 15}
                      radius={130 - index * 15}
                      startAngle={0}
                      endAngle={360 * (d.y / 100) - 90}
                      cornerRadius={15}
                      labels={() => null}
                      labelPosition={({ index }) => 'startAngle'}
                      style={{
                        data: {
                          fill: ({ datum }) =>
                            datum.y === d.y ? colors[index] : 'transparent',
                        },
                      }}
                      labelComponent={
                        <VictoryLabel
                          angle={0}
                          textAnchor="end"
                          dy={30}
                          dx={-10}
                        />
                      }
                    />
                  </>
                ))}
                {[10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map(
                  (percentage, index) => (
                    <VictoryLabel
                      key={index}
                      textAnchor="middle"
                      verticalAnchor="middle"
                      x={
                        200 +
                        160 *
                          Math.cos(
                            ((percentage / 100) * 270 - 90) * (Math.PI / 180),
                          )
                      }
                      y={
                        200 +
                        160 *
                          Math.sin(
                            ((percentage / 100) * 270 - 90) * (Math.PI / 180),
                          )
                      }
                      text={`${percentage}%`}
                      style={{ fill: 'rgba(1,1,1,0.6)', fontSize: 10 }}
                    />
                  ),
                )}
              </svg>
            </Grid>
          </Grid>
          <Grid container justify="space-between">
            <Grid
              item
              xs={12}
              container
              justify="flex-end"
              alignItems="center"
              style={{ padding: '0px 20px' }}
            >
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                  }}
                  className={classes.fontSmall}
                >
                  certified
                </Typography>
                <div className="skilled-box"></div>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                  }}
                  className={classes.fontSmall}
                >
                  billed
                </Typography>
                <div className="supervisor-box"></div>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                  }}
                  className={classes.fontSmall}
                >
                  debitted
                </Typography>
                <div
                  className="supervisor-box"
                  style={{ backgroundColor: 'rgb(77, 121, 246)' }}
                ></div>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                  }}
                  className={classes.fontSmall}
                >
                  payable
                </Typography>
                <div
                  className="supervisor-box"
                  style={{ backgroundColor: 'rgb(225, 118, 70)' }}
                ></div>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 20,
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{
                    color: 'rgba(178, 190, 199, 1)',
                  }}
                  className={classes.fontSmall}
                >
                  paid
                </Typography>
                <div className="skilled-box"></div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
