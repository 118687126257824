import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { contractorProfileStyle } from './styles/theme.contractorLis';
import Page from 'src/components/Page';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useNavigate } from 'react-router';
import ContractorWorkHistoryTable from './components/ContractorWorkHistoryTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUpdateContractorV2,
  contractorReducer,
} from 'src/redux/contractors';
import { jobReducer } from 'src/redux/jobs';
import { useState } from 'react';
import toast from 'react-hot-toast';

const ContractorProfile = () => {
  const classes = contractorProfileStyle();
  const { selectedContractorProfile, entityTypes, loading } =
    useSelector(contractorReducer);
  const { skillsList } = useSelector(jobReducer);
  const [isEditMode, setIsEditMode] = useState(false);
  let dispatch = useDispatch(contractorReducer);
  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    firstName: yup.string().required('Full name is required'),
    companyName: yup.string().required('Company Name is required'),
    panNumber: yup.string().required('PAN Number is required'),
    billingAddress: yup.string().required('Billing Address is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    scopeOfWorkId: yup.string().required('Scope Of Work is required'),
    accountType: yup.string().required('Account Type is required'),
  });
  var formik = useFormik({
    initialValues: {
      firstName: selectedContractorProfile?.firstName ?? 'N/A',
      lastName: selectedContractorProfile?.lastName ?? 'N/A',
      entityTypeId:
        entityTypes?.filter(
          (item) => item.name === selectedContractorProfile?.entityType
        )[0]?.id ?? 0,
      companyName: selectedContractorProfile?.companyName ?? 'N/A',
      panNumber: selectedContractorProfile?.panNumber ?? 'N/A',
      billingAddress: selectedContractorProfile?.billingAddress ?? 'N/A',
      gstin: selectedContractorProfile?.gstin ?? 'N/A',
      aadharNumber: selectedContractorProfile?.aadharNumber ?? 'N/A',
      phoneNumber: selectedContractorProfile?.phoneNumber ?? 'N/A',
      email: selectedContractorProfile?.email ?? 'N/A',
      accountNumber: selectedContractorProfile?.accountNumber ?? 'N/A',
      ifsc: selectedContractorProfile?.ifsc ?? 'N/A',
      accountType: selectedContractorProfile?.accountType ?? 'N/A',
      scopeOfWorkId:
        skillsList?.filter(
          (item) => item.name === selectedContractorProfile?.scopeOfWork
        )[0]?.skillId ?? 'N/A',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        toast.error('Passwords do not match');
        return;
      }
      let loading = toast.loading('Updating Contractor...');

      let resp = await dispatch(
        addUpdateContractorV2({
          ...values,
          contractorID: selectedContractorProfile?.contractorID,
        })
      );
      if (resp.data.success) {
        toast.dismiss(loading);
        toast.success('Contractor updated successfully');
        setIsEditMode(false);
      } else {
        toast.dismiss(loading);
        toast.error(
          resp.data.errors[0] ?? 'Something went wrong - validation failed'
        );
      }
    },
  });

  //!METHODS
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1);
  };
  const makeEditModeOn = () => {
    setIsEditMode(true);
  };
  const makeEditModeOff = () => {
    setIsEditMode(false);
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Contractor">
      <Grid
        container
        style={{
          padding: '0 2rem',
        }}
      >
        <Grid item>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <IconButton>
                <Grid item style={{ color: 'black' }} onClick={handleBack}>
                  <ArrowBackIcon color="black" fontSize="default" />
                </Grid>
              </IconButton>
            </Grid>
            {isEditMode ? (
              <Grid
                item
                container
                xs={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Button
                  onClick={formik.handleSubmit}
                  className={classes.buttonGreen}
                  style={{
                    marginRight: '10px',
                    textTransform: 'capitalize',
                  }}
                  type="submit"
                >
                  Update{' '}
                </Button>
                <Button
                  onClick={makeEditModeOff}
                  className={classes.buttonPurpleOpacity}
                >
                  Cancel
                </Button>
              </Grid>
            ) : (
              <Grid item>
                <Button
                  onClick={makeEditModeOn}
                  className={classes.buttonPurpleOpacity}
                >
                  Edit
                </Button>
              </Grid>
            )}
          </Grid>
          <form onSubmit={formik.handleSubmit}>
            <Grid
              container
              style={{
                padding: '1rem 10px',
                borderRadius: '10px',
                position: 'relative',
                margin: '10px 0',
                background:
                  'linear-gradient(white, white) padding-box, linear-gradient(45deg, #81B734, #A179F2) border-box',
                border: '1px solid transparent',
              }}
            >
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="FIRST NAME*"
                    fullWidth
                    variant="filled"
                    id="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.firstName &&
                      Boolean(formik.errors.firstName)
                    }
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="LAST NAME (Optional)"
                    fullWidth
                    variant="filled"
                    id="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.lastName && Boolean(formik.errors.lastName)
                    }
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    select
                    label="ENTITY TYPE"
                    style={{
                      fontFamily: 'Roboto',
                      '& .MuiFilledInput-inputMarginDense': {
                        paddingTop: '110px',
                      },
                    }}
                    variant="filled"
                    fullWidth
                    id="entityTypeId"
                    name="entityTypeId"
                    value={formik.values.entityTypeId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.entityTypeId &&
                      Boolean(formik.errors.entityTypeId)
                    }
                    helperText={
                      formik.touched.entityTypeId && formik.errors.entityTypeId
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    {entityTypes?.map((entity) => (
                      <MenuItem
                        value={entity.id}
                        key={entity.id}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {entity.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="Company Name/Proprietor's Name*"
                    fullWidth
                    variant="filled"
                    id="companyName"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.companyName &&
                      Boolean(formik.errors.companyName)
                    }
                    helperText={
                      formik.touched.companyName && formik.errors.companyName
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>{' '}
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="PAN NO*"
                    fullWidth
                    variant="filled"
                    id="panNumber"
                    name="panNumber"
                    value={formik.values.panNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.panNumber &&
                      Boolean(formik.errors.panNumber)
                    }
                    helperText={
                      formik.touched.panNumber && formik.errors.panNumber
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="BILLING ADDRESS*"
                    fullWidth
                    variant="filled"
                    id="billingAddress"
                    name="billingAddress"
                    value={formik.values.billingAddress}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.billingAddress &&
                      Boolean(formik.errors.billingAddress)
                    }
                    helperText={
                      formik.touched.billingAddress &&
                      formik.errors.billingAddress
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="GSTIN (Optional)"
                    fullWidth
                    variant="filled"
                    id="gstin"
                    name="gstin"
                    value={formik.values.gstin}
                    onChange={formik.handleChange}
                    error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                    helperText={formik.touched.gstin && formik.errors.gstin}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="AADHAR NO (Optional)"
                    fullWidth
                    variant="filled"
                    id="aadharNumber"
                    name="aadharNumber"
                    value={formik.values.aadharNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.aadharNumber &&
                      Boolean(formik.errors.aadharNumber)
                    }
                    helperText={
                      formik.touched.aadharNumber && formik.errors.aadharNumber
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>{' '}
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="PHONE NUMBER*"
                    fullWidth
                    variant="filled"
                    id="phoneNumber"
                    name="phoneNumber"
                    value={formik.values.phoneNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="EMAIL (Optional)"
                    fullWidth
                    variant="filled"
                    id="email"
                    name="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: classes.textFieldStyle,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    label="ACCOUNT NUMBER (Optional)"
                    fullWidth
                    variant="filled"
                    id="accountNumber"
                    name="accountNumber"
                    value={formik.values.accountNumber}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.accountNumber &&
                      Boolean(formik.errors.accountNumber)
                    }
                    helperText={
                      formik.touched.accountNumber &&
                      formik.errors.accountNumber
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    className={classes.commonStyle}
                    readOnly
                    disabled={!isEditMode}
                    size="small"
                    label="IFSC CODE (Optional)"
                    fullWidth
                    variant="filled"
                    id="ifsc"
                    name="ifsc"
                    value={formik.values.ifsc}
                    onChange={formik.handleChange}
                    error={formik.touched.ifsc && Boolean(formik.errors.ifsc)}
                    helperText={formik.touched.ifsc && formik.errors.ifsc}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>{' '}
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    select
                    label="ACCOUNT TYPE*"
                    style={{
                      fontFamily: 'Roboto',
                      '& .MuiFilledInput-inputMarginDense': {
                        paddingTop: '110px',
                      },
                    }}
                    variant="filled"
                    fullWidth
                    id="accountType"
                    name="accountType"
                    value={formik.values.accountType}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.accountType &&
                      Boolean(formik.errors.accountType)
                    }
                    helperText={
                      formik.touched.accountType && formik.errors.accountType
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    <MenuItem value="Developer">Developer</MenuItem>
                    <MenuItem value="Contractor">General Contractor</MenuItem>
                    <MenuItem value="SubContractor">Sub Contractor</MenuItem>
                    <MenuItem value="LabourContractor">
                      Labour Contractor
                    </MenuItem>
                    <MenuItem value="Individual">Individual</MenuItem>
                    <MenuItem value="Consultant">Consultant</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditMode}
                    className={classes.commonStyle}
                    size="small"
                    select
                    label="SCOPE OF WORK"
                    style={{
                      fontFamily: 'Roboto',
                      '& .MuiFilledInput-inputMarginDense': {
                        paddingTop: '110px',
                      },
                    }}
                    variant="filled"
                    fullWidth
                    id="scopeOfWorkId"
                    name="scopeOfWorkId"
                    value={formik.values.scopeOfWorkId}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.scopeOfWorkId &&
                      Boolean(formik.errors.scopeOfWorkId)
                    }
                    helperText={
                      formik.touched.scopeOfWorkId &&
                      formik.errors.scopeOfWorkId
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  >
                    {skillsList?.map((skill) => (
                      <MenuItem
                        key={skill.skillId}
                        value={skill.skillId}
                        style={{ fontFamily: 'Roboto' }}
                      >
                        {skill.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
              <Grid container item className={classes.commonStyleInput}>
                <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                  <TextField
                    disabled={!isEditMode}
                    label="SET PASSWORD*"
                    fullWidth
                    variant="filled"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.password && Boolean(formik.errors.password)
                    }
                    helperText={
                      formik.touched.password && formik.errors.password
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}`,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    disabled={!isEditMode}
                    label="CONFIRM PASSWORD*"
                    fullWidth
                    variant="filled"
                    id="confirmPassword"
                    name="confirmPassword"
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.confirmPassword &&
                      Boolean(formik.errors.confirmPassword)
                    }
                    helperText={
                      formik.touched.confirmPassword &&
                      formik.errors.confirmPassword
                    }
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        input: `${classes.textFieldStyle}  `,
                        root: classes.input,
                      },
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </form>

          <Grid item container>
            <ContractorWorkHistoryTable
              loading={loading}
              historyTable={selectedContractorProfile?.workHistory}
            />
          </Grid>
        </Grid>
      </Grid>
    </Page>
  );
};
export default ContractorProfile;
