import { Grid, Typography } from '@material-ui/core';
import { Popover, ConfigProvider } from 'antd';

import React, { useEffect, useState } from 'react';
//deploy roles
const ContractorProgressLinearChart = (props) => {
  const {
    insightData,
    date,
    grossTotal,
    renderPercentage = false,
    renderAmount = true,
    summaryLabel,
  } = props;

  const [sortedSections, setSortedSections] = useState([]);

  const grossTotalInLakh = Math.round(grossTotal / 100000);
  const divElements = {};

  useEffect(() => {
    if (insightData) {
      const shallowCopy = [...insightData];
      shallowCopy.sort((a, b) => a.percentage - b.percentage);
      setSortedSections(shallowCopy);
    }
  }, [insightData]);

  const setDivRef = (element, index) => {
    if (element) {
      divElements[index] = element;
      const maxWidth =
        sortedSections[index].percentage -
        (sortedSections[index - 1]?.percentage || 0);
      const delay = index * 300;
      setTimeout(() => {
        element.style.maxWidth = `${maxWidth}%`;
      }, delay);
    }
  };

  const calculateBorderRadius = (itemIndex) => {
    let borderRadius = 'none';
    const isLastCell = sortedSections.length - 1 === itemIndex;
    const isFirstCell = itemIndex === 0;

    if (isFirstCell && isLastCell) {
      borderRadius = '10px';
    } else if (isFirstCell) {
      borderRadius = '10px 0 0 10px';
    } else if (isLastCell) {
      borderRadius = '0 10px 10px 0';
    }
    return borderRadius;
  };

  const PopoverContent = ({ item, actualValue }) => {
    const valueInLakhs = Math.round(actualValue / 100000);
    return (
      <Grid container>
        <Typography
          variant="subtitle1"
          style={{
            color: 'white',
            fontSize: '14px',
            width: '100%',
          }}
          align="center"
        >
          {renderAmount && `${valueInLakhs} Lakhs`}
          {renderPercentage && `${item.percentage}%`}
        </Typography>
        <Typography
          variant="subtitle2"
          style={{
            color: 'white',
            fontSize: '10px',
            width: '100%',
            fontWeight: 300,
          }}
          align="center"
        >
          {item.heading}
        </Typography>
      </Grid>
    );
  };

  const renderProgress = () => {
    function extractSolidColor(color) {
      const colorRegex = /#([a-f0-9]{8}|[a-f0-9]{6})/gi;
      const matches = color.match(colorRegex);
      return matches ? matches[0] : color;
    }

    return (
      <Grid
        item
        container
        xs={12}
        style={{
          height: 15,
          background: 'white',
          borderRadius: '23px',
          border: '1px solid #ACCBE8',
          padding: 2,
        }}
      >
        {sortedSections.map((item, index) => {
          const borderRadius = calculateBorderRadius(index);
          return (
            <ConfigProvider
              theme={{
                components: {
                  Popover: {
                    titleMinWidth: 120,
                    padding: 0,
                    colorBgElevated: extractSolidColor(item.color),
                    color: 'white',
                    sizePopupArrow: 22,
                  },
                },
              }}
            >
              <Popover
                key={index}
                content={
                  <PopoverContent
                    actualValue={(grossTotal * item.percentage) / 100}
                    item={item}
                  />
                }
                popupAlign={{ offset: [18, -28] }}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
                placement="topRight"
                trigger="hover"
              >
                <Grid
                  item
                  ref={(element) => setDivRef(element, index)}
                  style={{
                    width: '100%',
                    maxWidth: '0px',
                    background: item.color,
                    borderRadius,
                    transition: 'max-width 1s ease-out',
                  }}
                />
              </Popover>
            </ConfigProvider>
          );
        })}
      </Grid>
    );
  };

  return (
    <Grid item xs={12} container>
      <Grid item container justify="space-between">
        {summaryLabel}
        {date}
      </Grid>
      <Grid
        item
        container
        style={{
          alignSelf: 'flex-end',
          //           margin: 10,
        }}
      >
        {renderPercentage && (
          <Grid
            item
            container
            justify="space-between"
            style={{ alignSelf: 'flex-end', margin: '5px 20px' }}
          >
            <Typography style={{ fontSize: 11, color: 'white' }}>0%</Typography>
            <Typography style={{ fontSize: 11, color: 'white' }}>
              100%
            </Typography>
          </Grid>
        )}
        {sortedSections.length > 0 && renderProgress()}
        {renderAmount && (
          <Grid
            item
            container
            justify="space-between"
            style={{ alignSelf: 'flex-end', margin: '5px 20px' }}
          >
            <Typography style={{ fontSize: 11, color: 'white' }}>0</Typography>
            <Typography style={{ fontSize: 11, color: 'white' }}>
              {`${grossTotalInLakh} Lakhs`}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ContractorProgressLinearChart;
