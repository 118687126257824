import { createContractorStyle } from '../styles/theme.contractorLis';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useFormik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import {
  addUpdateContractorV2,
  contractorReducer,
  getContractorListing,
} from 'src/redux/contractors';
import { jobReducer } from 'src/redux/jobs';
import * as yup from 'yup';

const CreateContractorModal = ({ open, handleClose }) => {
  const classes = createContractorStyle();
  let dispatch = useDispatch();
  const { skillsList } = useSelector(jobReducer);
  const { entityTypes, loading } = useSelector(contractorReducer);

  //! METHODS
  //* 1. FORMIK
  const validationSchema = yup.object({
    firstName: yup.string().required('Full name is required'),
    companyName: yup.string().required('Company Name is required'),
    panNumber: yup.string().required('PAN Number is required'),
    billingAddress: yup.string().required('Billing Address is required'),
    phoneNumber: yup.string().required('Phone Number is required'),
    scopeOfWorkId: yup.string().required('Scope Of Work is required'),
    password: yup.string().required('Address is required'),
    confirmPassword: yup.string().required('Address is required'),
    accountTypeId: yup.string().required('Account Type is required'),
  });
  var formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      entityTypeId: 0,
      companyName: '',
      panNumber: '',
      billingAddress: '',
      gstin: '',
      aadharNumber: '',
      phoneNumber: '',
      email: '',
      accountNumber: '',
      ifsc: '',
      accountTypeId: '',
      scopeOfWorkId: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      if (values.password !== values.confirmPassword) {
        toast.error('Passwords do not match');
        return;
      }
      let loading = toast.loading('Creating Contractor...');

      let resp = await dispatch(addUpdateContractorV2(values));
      if (resp.data.success) {
        toast.dismiss(loading);
        toast.success('Contractor added successfully');
        handleClose();
        dispatch(getContractorListing());
      } else {
        toast.dismiss(loading);
        toast.error(resp.data.errors[0]);
      }
    },
  });

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      className={classes.modal}
      onClose={handleClose}
      PaperProps={{ style: { width: 900, height: '90%' } }}
    >
      <DialogTitle>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Add New Contractor</Typography>
          <CloseIcon className={classes.pointerCursor} onClick={handleClose} />
        </Grid>
      </DialogTitle>
      <Divider />

      <DialogContent>
        <form onSubmit={formik.handleSubmit}>
          <Grid container>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="FIRST NAME*"
                  fullWidth
                  variant="filled"
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="LAST NAME (Optional)"
                  fullWidth
                  variant="filled"
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  select
                  label="ENTITY TYPE"
                  size="small"
                  style={{
                    fontFamily: 'Roboto',
                    '& .MuiFilledInput-inputMarginDense': {
                      paddingTop: '110px',
                    },
                  }}
                  variant="filled"
                  fullWidth
                  id="entityTypeId"
                  name="entityTypeId"
                  value={formik.values.entityTypeId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.entityTypeId &&
                    Boolean(formik.errors.entityTypeId)
                  }
                  helperText={
                    formik.touched.entityTypeId && formik.errors.entityTypeId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                  loading={loading}
                >
                  {entityTypes?.map((entity) => (
                    <MenuItem
                      value={entity.id}
                      key={entity.id}
                      style={{ fontFamily: 'Roboto' }}
                    >
                      {entity.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Company Name/Proprietor's Name*"
                  fullWidth
                  variant="filled"
                  id="companyName"
                  name="companyName"
                  value={formik.values.companyName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.companyName &&
                    Boolean(formik.errors.companyName)
                  }
                  helperText={
                    formik.touched.companyName && formik.errors.companyName
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>{' '}
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="PAN NO*"
                  fullWidth
                  variant="filled"
                  id="panNumber"
                  name="panNumber"
                  value={formik.values.panNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.panNumber && Boolean(formik.errors.panNumber)
                  }
                  helperText={
                    formik.touched.panNumber && formik.errors.panNumber
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="BILLING ADDRESS*"
                  fullWidth
                  variant="filled"
                  id="billingAddress"
                  name="billingAddress"
                  value={formik.values.billingAddress}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.billingAddress &&
                    Boolean(formik.errors.billingAddress)
                  }
                  helperText={
                    formik.touched.billingAddress &&
                    formik.errors.billingAddress
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="gstin (Optional)"
                  fullWidth
                  variant="filled"
                  id="gstin"
                  name="gstin"
                  value={formik.values.gstin}
                  onChange={formik.handleChange}
                  error={formik.touched.gstin && Boolean(formik.errors.gstin)}
                  helperText={formik.touched.gstin && formik.errors.gstin}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="AADHAR NO (Optional)"
                  fullWidth
                  variant="filled"
                  id="aadharNumber"
                  name="aadharNumber"
                  value={formik.values.aadharNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.aadharNumber &&
                    Boolean(formik.errors.aadharNumber)
                  }
                  helperText={
                    formik.touched.aadharNumber && formik.errors.aadharNumber
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>{' '}
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="PHONE NUMBER*"
                  fullWidth
                  variant="filled"
                  id="phoneNumber"
                  name="phoneNumber"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.phoneNumber &&
                    Boolean(formik.errors.phoneNumber)
                  }
                  helperText={
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="EMAIL (Optional)"
                  fullWidth
                  variant="filled"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: classes.textFieldStyle,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="ACCOUNT NUMBER (Optional)"
                  fullWidth
                  variant="filled"
                  id="accountNumber"
                  name="accountNumber"
                  value={formik.values.accountNumber}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountNumber &&
                    Boolean(formik.errors.accountNumber)
                  }
                  helperText={
                    formik.touched.accountNumber && formik.errors.accountNumber
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="IFSC CODE (Optional)"
                  fullWidth
                  variant="filled"
                  id="ifsc"
                  name="ifsc"
                  value={formik.values.ifsc}
                  onChange={formik.handleChange}
                  error={formik.touched.ifsc && Boolean(formik.errors.ifsc)}
                  helperText={formik.touched.ifsc && formik.errors.ifsc}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>{' '}
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  select
                  label="ACCOUNT TYPE*"
                  size="small"
                  style={{
                    fontFamily: 'Roboto',
                    '& .MuiFilledInput-inputMarginDense': {
                      paddingTop: '110px',
                    },
                  }}
                  variant="filled"
                  fullWidth
                  id="accountTypeId"
                  name="accountTypeId"
                  value={formik.values.accountTypeId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.accountTypeId &&
                    Boolean(formik.errors.accountTypeId)
                  }
                  helperText={
                    formik.touched.accountTypeId && formik.errors.accountTypeId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                >
                  <MenuItem value="Developer">Developer</MenuItem>
                  <MenuItem value="Contractor">General Contractor</MenuItem>
                  <MenuItem value="SubContractor">Sub Contractor</MenuItem>
                  <MenuItem value="LabourContractor">
                    Labour Contractor
                  </MenuItem>
                  <MenuItem value="Individual">Individual</MenuItem>
                  <MenuItem value="Consultant">Consultant</MenuItem>
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  select
                  label="SCOPE OF WORK"
                  size="small"
                  style={{
                    fontFamily: 'Roboto',
                    '& .MuiFilledInput-inputMarginDense': {
                      paddingTop: '110px',
                    },
                  }}
                  variant="filled"
                  fullWidth
                  id="scopeOfWorkId"
                  name="scopeOfWorkId"
                  value={formik.values.scopeOfWorkId}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.scopeOfWorkId &&
                    Boolean(formik.errors.scopeOfWorkId)
                  }
                  helperText={
                    formik.touched.scopeOfWorkId && formik.errors.scopeOfWorkId
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                >
                  {skillsList?.map((skill) => (
                    <MenuItem
                      key={skill.skillId}
                      value={skill.skillId}
                      style={{ fontFamily: 'Roboto' }}
                    >
                      {skill.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container item className={classes.commonStyleInput}>
              <Grid item xs={6} style={{ paddingRight: '1rem' }}>
                <TextField
                  label="SET PASSWORD*"
                  fullWidth
                  variant="filled"
                  id="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}`,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="CONFIRM PASSWORD*"
                  fullWidth
                  variant="filled"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      input: `${classes.textFieldStyle}  `,
                      root: classes.input,
                    },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'right' }}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.buttonGreen}
                style={{ marginRight: 10 }}
              >
                Create Contractor
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.buttonPurpleOpacity}
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CreateContractorModal;
