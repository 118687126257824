import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { contractorWorkHistoryTableStyle } from '../styles/theme.contractorLis';

const ContractorWorkHistoryTable = ({ loading, historyTable }) => {
  let classes = contractorWorkHistoryTableStyle();
  let filteredArray = [];
  return (
    <Grid item container className={classes.border}>
      <Grid item container alignItems="center" justifyContent="center">
        <Typography
          variant="h6"
          align="center"
          className={classes.paddingBottom}
        >
          {' '}
          Work History
        </Typography>
      </Grid>

      <Grid item xs={12}>
        {loading ? (
          <Typography align="center">Fetching Details ... </Typography>
        ) : (
          <TableContainer style={{ marginTOp: '1rem' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Client
                  </TableCell>{' '}
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Project Name
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    SOW
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Order Value
                  </TableCell>
                  <TableCell
                    size="small"
                    align="left"
                    // style={{ fontSize: 12, fontFamily: 'Roboto' }}
                    className={classes.headerCellText1}
                  >
                    Period
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {filteredArray?.length ? (
                  filteredArray?.map((val, index) => (
                    <TableRow hover key={index}>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.client}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.projectName}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.scopeOfWork}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.orderValue}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.period}
                      </TableCell>
                    </TableRow>
                  ))
                ) : historyTable?.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      No Data Found!
                    </TableCell>
                  </TableRow>
                ) : (
                  historyTable?.map((val, index) => (
                    <TableRow hover key={index}>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.client}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.projectName}
                      </TableCell>

                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.scopeOfWork}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.orderValue}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          fontSize: 12,
                          fontWeight: 'bold',
                          fontFamily: 'Roboto',
                        }}
                      >
                        {val.period}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Grid>
    </Grid>
  );
};
export default ContractorWorkHistoryTable;
