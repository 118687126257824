import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../services/config";

const initialState = {
  loading: false,
  error: null,
  usersList: null,
  selectedUser: null,
  contractorList: null,
  contractorListLoading: false,
  updateLoggedinUserProfileLoading: false,
  loggedInUserDetail: null,
};

const slice = createSlice({
  name: "users",
  initialState,
  reducers: {
    gettingUsers(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingUsersSuccess(state, action) {
      state.usersList = action.payload;
      state.loading = false;
      state.error = null;
    },
    gettingUsersFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    selectAUser: (state, action) => {
      state.selectedUser = action.payload;
    },
    creatitngUser(state, action) {
      state.loading = true;
      state.error = null;
    },
    creatitngUserSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    creatitngUserFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingLabourContracors(state, action) {
      state.contractorListLoading = true;
      state.error = null;
    },
    gettingLabourContracorsSuccess(state, action) {
      state.contractorList = action.payload;
      state.contractorListLoading = false;
      state.error = null;
    },
    gettingLabourContracorsFailure(state, action) {
      state.contractorListLoading = false;
      state.error = action.payload;
      state.contractorList = null;
    },
    updatingLoggedinUserProfile(state, action) {
      state.updateLoggedinUserProfileLoading = true;
      state.error = null;
    },
    updatingLoggedinUserProfileSuccess(state, action) {
      state.updateLoggedinUserProfileLoading = false;
      state.error = null;
    },
    updatingLoggedinUserProfileFailure(state, action) {
      state.updateLoggedinUserProfileLoading = false;
      state.error = action.payload;
    },

    gettingLoggedInUserDetail(state, action) {
      state.error = null;
    },
    gettingLoggedInUserDetailSuccess(state, action) {
      state.loggedInUserDetail = action.payload;
      state.error = null;
    },
    gettingLoggedInUserDetailFailure(state, action) {
      state.error = action.payload;
    },
  },
});
const {
  gettingUsers,
  gettingUsersSuccess,
  gettingUsersFailure,
  selectAUser,
  creatitngUser,
  creatitngUserSuccess,
  creatitngUserFailure,
  gettingLabourContracors,
  gettingLabourContracorsSuccess,
  gettingLabourContracorsFailure,
  updatingLoggedinUserProfile,
  updatingLoggedinUserProfileSuccess,
  updatingLoggedinUserProfileFailure,
  gettingLoggedInUserDetail,
  gettingLoggedInUserDetailSuccess,
  gettingLoggedInUserDetailFailure,
} = slice.actions;

export const usersReducer = (state) => state.users;
export const getUsers = () => async (dispatch) => {
  dispatch(gettingUsers());
  try {
    const response = await axios.get(`${config.baseUrl}/dashboard/User/getall`);
    if (response.data) {
      dispatch(gettingUsersSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(gettingUsersFailure("Something went wrong while getting users!"));
  }
};

export const pickAUser = (user) => async (dispatch) => {
  dispatch(selectAUser(user));
};

export const craeteUser = (user) => async (dispatch) => {
  dispatch(creatitngUser());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/User/addupdateuser`,
      {
        ...user,
        userTypeId: 0,
        contractorId: 0,
      }
    );

    if (response?.status === 200) {
      dispatch(creatitngUserSuccess(response.data));
    }
    return response;
  } catch (e) {

    dispatch(creatitngUserFailure("Something went wrong while getting users!"));
    return e.message;
  }
};

export const getContractors = (projectId) => async (dispatch) => {
  dispatch(gettingLabourContracors());
  try {
    let url = projectId
      ? `${config.baseUrl}/dashboard/User/labourcontractor?projectId=${projectId}`
      : `${config.baseUrl}/dashboard/User/labourcontractor`;

    const response = await axios.get(url);
    if (response.status === 200) {
      dispatch(gettingLabourContracorsSuccess(response.data));
    } else {
      dispatch(gettingLabourContracorsSuccess([]));
    }

    return response;
  } catch (e) {
    // console.log("Caught");
    dispatch(
      gettingLabourContracorsFailure(
        "Something went wrong while getting labour contractor!"
      )
    );
    return e;
  }
};

export const updateLoggedInUserProfile = (data) => async (dispatch) => {
  dispatch(updatingLoggedinUserProfile());
  try {
    const response = await axios.put(
      `${config.baseUrl}/dashboard/User/editprofile`,
      data
    );

    if (response?.status === 200) {
      dispatch(updatingLoggedinUserProfileSuccess());
    }
    return response;
  } catch (e) {

    dispatch(
      updatingLoggedinUserProfileFailure(
        "Something went wrong while updating user profile!"
      )
    );
    return e.message;
  }
};

export const getLoggedInUserDetail = () => async (dispatch) => {
  dispatch(gettingLoggedInUserDetail());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/user/getprofile`
    );
    if (response.data) {
      dispatch(gettingLoggedInUserDetailSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingLoggedInUserDetailFailure(
        "Something went wrong while getting loggedIn users!"
      )
    );
  }
};

export default slice.reducer;
