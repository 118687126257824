import React from 'react';
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import * as yup from 'yup';
import { useToasts } from 'react-toast-notifications';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { createRole, rolesReducer } from 'src/redux/roles';
import { featuresReducer, getFeatures } from 'src/redux/features';
import { useEffect } from 'react';
import { useState } from 'react';

// import { useNavigate } from 'react-router';
// import { Link } from 'react-router-dom';
// import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// import AddCircleIcon from '@material-ui/icons/AddCircle';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    padding: '1rem',
    borderRadius: '10px',
    background: '#fff',
  },
  btn: {
    fontSize: '12px',
    // backgroundColor: 'rgba(129, 183, 52, 1)',
    // color: '#fff',
    fontFamily: 'Roboto',
    padding: '.6rem 1.3rem',
    textTransform: 'capitalize',
    borderRadius: '10px',
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
  },
  backdrop: {
    color: 'rgba(129, 183, 52, 1)',
  },
  table: {
    maxHeight: '85vh',
  },
  font: {
    fontFamily: 'Roboto',
  },
  [theme.breakpoints.down('sm')]: {},
}));
const AddRoles = ({ fetchFunction }) => {
  const [listOfFeatures, setListOfFeatures] = useState([]);
  // const [roleData, setRoleData] = useState(null);

  const routesMap = new Map();
  const scopes = [
    'dashboard',
    'project',
    'jobs',
    'attendance',
    // 'communication',
    'worker',
    'payments',
    'user',
    'reports',
    // 'leads',
    // 'features',
    //'roles',
    'rewards',
    'productivity',
    'field notes',
  ];
  let temp = [];

  //! INSTANCES
  let dispatch = useDispatch();
  let classes = useStyles();
  const { addToast } = useToasts();
  // let navigate = useNavigate();

  //! SELCTORS
  const { loading } = useSelector(rolesReducer);
  const { loading: featureLoading, featuresList } =
    useSelector(featuresReducer);

  const getFeatureCall = async () => {
    await dispatch(getFeatures());
    let array = featuresList.map((item) => ({ isChecked: false, ...item }));

    if (array?.length > 0) {
      scopes.forEach((scope) => {
        for (let feature of array) {
          if (feature.name.toLowerCase().includes(scope)) {
            if (routesMap.has(scope)) routesMap.get(scope).push(feature);
            else routesMap.set(scope, [feature]);
          }
        }
      });

      routesMap.forEach((value, key) => {
        // console.log('value', value);
        temp.push({ children: value, scope: key });
      });

      setListOfFeatures(temp);
    }
  };

  //! LIFE-CYCLES
  useEffect(() => {
    if (listOfFeatures.length === 0) {
      getFeatureCall();
    }
  }, [featuresList.length]);

  const validationSchema = yup.object({
    name: yup.string().required('Name is required'),
  });

  const formik = useFormik({
    initialValues: {
      roleId: 0,
      name: '',
      featureSetIds: [],
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let checkArray = [];
      listOfFeatures.map((item) => {
        item.children.filter((child) => {
          if (child.isChecked) {
            checkArray.push(child);
          }
        });
      });
      if (checkArray.length > 0) {
        let newObj = {
          ...values,
          featureSetIds: checkArray.map((item) => item.featureSetId),
        };
        let newObjWithStaticId = {
          ...newObj,
          featureSetIds: [...newObj.featureSetIds, 10, 26], //Comm,profile
        };

        // let resp = await dispatch(createRole(newObjWithStaticId));

        // if (resp?.status === 200) {
        //   addToast(`${values.name} Role Added Successfully!`, {
        //     appearance: 'success',
        //     autoDismiss: true,
        //   });
        //   // navigate(-1);
        // } else {
        //   addToast(`${values.name} Role Already Exists!`, {
        //     appearance: 'error',
        //     autoDismiss: true,
        //   });
        // }
      } else {
        addToast(`Please select at least one feature to proceed!`, {
          appearance: 'error',
          autoDismiss: true,
        });
      }
    },
  });
  //

  useEffect(() => {
    fetchFunction(formik);
  }, []);

  // 	const handleAllCheckboxChange = (isChecked) => {
  // 		if (isChecked) {
  // 			setListOfFeatures(
  // 				featuresList.map((item) => ({
  // 					...item,
  // 					isChecked: true,
  // 				}))
  // 			);
  // 		} else {
  // 			setListOfFeatures(
  // 				featuresList.map((item) => ({
  // 					...item,
  // 					isChecked: false,
  // 				}))
  // 			);
  // 		}
  // 	};
  const handleCheckbox = (row, isChecked, name) => {
    if (name === 'View') {
      let data = listOfFeatures.map((item) => {
        if (isChecked && row.scope === item.scope) {
          return {
            scope: item.scope,
            children: item.children.map((child, index) => {
              if (child.name.toLowerCase().includes('list')) {
                return { ...child, isChecked: true };
              } else {
                return child;
              }
            }),
          };
        } else if (!isChecked && row.scope === item.scope) {
          return {
            scope: item.scope,
            children: item.children.map((child, index) => {
              if (child.name.toLowerCase().includes('list')) {
                return { ...child, isChecked: false };
              } else {
                return child;
              }
            }),
          };
        } else {
          return item;
        }
      });
      setListOfFeatures([...data]);
    }
    if (name === 'Detail') {
      let data = listOfFeatures.map((item) => {
        if (isChecked && row.scope === item.scope) {
          return {
            scope: item.scope,
            children: item.children.map((child, index) => {
              if (child.name.toLowerCase().includes('detail')) {
                return { ...child, isChecked: true };
              } else {
                return child;
              }
            }),
          };
        } else if (!isChecked && row.scope === item.scope) {
          return {
            scope: item.scope,
            children: item.children.map((child, index) => {
              if (child.name.toLowerCase().includes('detail')) {
                return { ...child, isChecked: false };
              } else {
                return child;
              }
            }),
          };
        } else {
          return item;
        }
      });
      setListOfFeatures([...data]);
    }
    if (name === 'Edit') {
      let data = listOfFeatures.map((item) => {
        if (isChecked && row.scope === item.scope) {
          return {
            scope: item.scope,
            children: item.children.map((child, index) => {
              if (
                child.name.toLowerCase().includes('edit') ||
                child.name.toLowerCase().includes('create')
              ) {
                return { ...child, isChecked: true };
              } else {
                return child;
              }
            }),
          };
        } else if (!isChecked && row.scope === item.scope) {
          return {
            scope: item.scope,
            children: item.children.map((child, index) => {
              if (
                child.name.toLowerCase().includes('edit') ||
                child.name.toLowerCase().includes('create')
              ) {
                return { ...child, isChecked: false };
              } else {
                return child;
              }
            }),
          };
        } else {
          return item;
        }
      });
      setListOfFeatures([...data]);
    }
  };

  return (
    <Grid container justify="center" xs={12}>
      {/* <Grid item container xs={12} alignItems='center'>
				<IconButton onClick={() => navigate(-1)}>
					<ArrowBackIcon color='black' fontSize='default' />
				</IconButton>
				<Typography variant='h5' style={{ fontFamily: 'Roboto' }}>
					Back
				</Typography>
			</Grid> */}

      <Grid item container xs={12}>
        <TableContainer
          //className={classes.table}
          component={Paper}
          style={{
            background: 'none',
            //border:"0px",
            //marginTop: '1rem',
            //borderRadius: '10px',
            //padding: '.5rem',
            position: 'relative',
          }}
        >
          {(loading || featureLoading) && (
            <div style={{ position: 'absolute', left: '45%', top: '50%' }}>
              <CircularProgress color="inherit" className={classes.backdrop} />
            </div>
          )}

          <Grid item xs={12}>
            <form onSubmit={formik.handleSubmit} style={{ width: '100%' }}>
              <Grid
                item
                container
                // className={`${classes.mainContainer}`}
                xs={12}
                justify="space-between"
                alignItems="center"
              >
                <Grid item md={12} xs={12} style={{ marginBottom: '1rem' }}>
                  <TextField
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    placeholder="Enter New Role Name Here"
                    fullWidth
                    inputProps={{
                      style: { fontFamily: 'Roboto' },
                    }}
                    // size='small'
                    variant="outlined"
                  />{' '}
                  <p
                    style={{
                      margin: '4px 14px 0px',
                      color: 'red',
                      fontSize: '12px',
                      fontWeight: '0',
                    }}
                  >
                    {formik.touched.name && formik.errors.name}
                  </p>
                </Grid>

                {/* <Grid item md={3} xs={12}>
									<Button
										fullWidth
										variant='contained'
										className={`${classes.btn} `}
										startIcon={<AddCircleIcon />}
										// type='submit'
										onClick={() => formik.submitForm()}
										disabled={loading}>
										Add New Role
									</Button>
								</Grid> */}
              </Grid>
            </form>
          </Grid>
          <Grid item style={{ padding: ' 0 0 1.5rem .5rem', fontSize: '12px' }}>
            {' '}
            Select features from below list which you want to assign to this new
            role*
          </Grid>

          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: '16px',
                    fontWeight: 700,
                    background: 'none',
                  }}
                  className={classes.font}
                >
                  Name
                </TableCell>
                <TableCell
                  style={{
                    fontSize: '16px',
                    fontWeight: 700,
                    background: 'none',
                  }}
                  className={classes.font}
                >
                  Access
                </TableCell>
                {/* <TableCell>Icon</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {listOfFeatures.map((row, index) => (
                <TableRow key={index}>
                  <TableCell
                    component="th"
                    scope="row"
                    style={{ fontSize: '15px', textTransform: 'capitalize' }}
                    className={classes.font}
                  >
                    {row.scope ?? 'N/A'}
                  </TableCell>

                  {/* <TableCell component='th' scope='row'>
										{row.children?.length > 0 &&
											row?.children.map((item) => (
												<>
													<FormControlLabel
														control={
															<Checkbox
																checked={item.isChecked}
																onChange={(e) =>
																	handleCheckbox(
																		item.name,
																		item.featureSetId,
																		e.target.checked
																	)
																}
															/>
														}
														label={
															<Typography className={classes.font}>
																{item.name.includes('List')
																	? 'View'
																	: item.name.includes('Edit') && 'Create/Edit'}
															</Typography>
														}
													/>
												</>
											))}
									</TableCell> */}
                  <TableCell component="th" scope="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={item.isChecked}
                          onChange={(e) =>
                            handleCheckbox(row, e.target.checked, 'View')
                          }
                        />
                      }
                      label={
                        <Typography className={classes.font}>View</Typography>
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          // checked={item.isChecked}
                          onChange={(e) =>
                            handleCheckbox(row, e.target.checked, 'Detail')
                          }
                        />
                      }
                      label={
                        <Typography className={classes.font}>Detail</Typography>
                      }
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleCheckbox(row, e.target.checked, 'Edit')
                          }
                          // checked={item.isChecked}
                          // onChange={(e) =>
                          // 	handleCheckbox(
                          // 		item.name,
                          // 		item.featureSetId,
                          // 		e.target.checked
                          // 	)
                          // }
                        />
                      }
                      label={
                        <Typography className={classes.font}>
                          Create/Edit
                        </Typography>
                      }
                    />
                  </TableCell>

                  {/* <TableCell component='th' scope='row'>
										{row.icon ?? 'N/A'}
									</TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Grid>
  );
};

export default AddRoles;
