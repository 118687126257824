import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import config from '../services/config';

const initialState = {
  loading: false,
  error: null,
  contractorsList: null,
  contractorListing: null,
  selectedContractor: null,
  selectedContractorProfile: null,
  entityTypes: null,
  stats: null,
};

const slice = createSlice({
  name: 'contractors',
  initialState,
  reducers: {
    gettingContractors(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingContractorsSuccess(state, action) {
      state.contractorsList = action.payload;
      state.loading = false;
      state.error = null;
    },
    gettingContractorsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    creatitngContractor(state, action) {
      state.loading = true;
      state.error = null;
    },
    creatitngContractorSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    creatitngContractorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },

    addingUpdatingContractor(state, action) {
      state.loading = true;
      state.error = null;
    },
    addingUpdatingContractorSuccess(state, action) {
      state.loading = false;
      state.error = null;
    },
    addingUpdatingContractorFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingContractorListing(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingContractorListingSuccess(state, action) {
      state.loading = false;
      state.error = null;
      state.contractorListing = action.payload;
    },
    gettingContractorListingFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.contractorListing = null;
    },
    selectingContractor(state, action) {
      state.selectedContractor = action.payload;
    },
    gettingContractorProfile(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingContractorProfileSuccess(state, action) {
      state.loading = false;
      state.selectedContractorProfile = action.payload;
    },
    gettingContractorProfileFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.selectedContractorProfile = null;
    },
    gettingEntityTypes(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingEntityTypesSuccess(state, action) {
      state.loading = false;
      state.entityTypes = action.payload;
    },
    gettingEntityTypesFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
      state.entityTypes = null;
    },
    linkingProjects(state, action) {
      state.loading = true;
      state.error = null;
    },
    linkingProjectsSuccess(state, action) {
      state.loading = false;
    },
    linkingProjectsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
    gettingContractorStats(state, action) {
      state.loading = true;
      state.error = null;
    },
    gettingContractorStatsSuccess(state, action) {
      state.loading = false;
      state.stats = action.payload;
    },
    gettingContractorStatsFailure(state, action) {
      state.loading = false;
      state.error = action.payload;
    },
  },
});
const {
  gettingContractors,
  gettingContractorsSuccess,
  gettingContractorsFailure,
  creatitngContractor,
  creatitngContractorSuccess,
  creatitngContractorFailure,
  addingUpdatingContractor,
  addingUpdatingContractorSuccess,
  addingUpdatingContractorFailure,
  gettingContractorListing,
  gettingContractorListingSuccess,
  gettingContractorListingFailure,
  selectingContractor,
  gettingContractorProfile,
  gettingContractorProfileSuccess,
  gettingContractorProfileFailure,
  gettingEntityTypes,
  gettingEntityTypesSuccess,
  gettingEntityTypesFailure,
  linkingProjects,
  linkingProjectsSuccess,
  linkingProjectsFailure,
  gettingContractorStats,
  gettingContractorStatsSuccess,
  gettingContractorStatsFailure,
} = slice.actions;
export const contractorReducer = (state) => state.contractors;

export const getContractors = () => async (dispatch) => {
  dispatch(gettingContractors());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Contractor/getall`
    );
    if (response.status === 200) {
      dispatch(gettingContractorsSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingContractorsFailure('Something went wrong while getting users!')
    );
  }
};

export const updateContractor = (worker) => async (dispatch) => {
  dispatch(creatitngContractor());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Contractor/addupdatecontractor`,
      worker
    );

    if (response.status === 200) {
      dispatch(creatitngContractorSuccess(response.data));
    }
    return response;
  } catch (e) {
    dispatch(
      creatitngContractorFailure('Something went wrong while getting users!')
    );
    return e;
  }
};

export const addUpdateContractorV2 = (data) => async (dispatch) => {
  dispatch(addingUpdatingContractor());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Contractor/addupdatecontractor/v2`,
      data
    );
    if (response.status === 200) {
      dispatch(addingUpdatingContractorSuccess(response.data));
    } else {
      dispatch(
        addingUpdatingContractorFailure(
          'Something went wrong while getting users!'
        )
      );
    }
    return response;
  } catch (e) {
    dispatch(
      addingUpdatingContractorFailure(
        'Something went wrong while getting users!'
      )
    );
    return e;
  }
};
export const getContractorListing = () => async (dispatch) => {
  dispatch(gettingContractorListing());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Contractor/listing`
    );
    if (response.status === 200) {
      dispatch(gettingContractorListingSuccess(response.data.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingContractorListingFailure(
        'Something went wrong while getting contractors!'
      )
    );
  }
};
export const selectContractor = (data) => async (dispatch) => {
  dispatch(selectingContractor(data));
};
export const getContractorProfile = (id) => async (dispatch) => {
  dispatch(gettingContractorProfile());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Contractor/profile?contractorId=${id}`
    );
    if (response.status === 200) {
      dispatch(gettingContractorProfileSuccess(response.data.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingContractorProfileFailure(
        'Something went wrong while getting contractors!'
      )
    );
  }
};
export const getEntityTypes = (data) => async (dispatch) => {
  dispatch(gettingEntityTypes());
  try {
    const response = await axios.get(
      `${config.baseUrl}/dashboard/Contractor/entityTypes`
    );
    if (response.status === 200) {
      dispatch(gettingEntityTypesSuccess(response.data.result));
    }
    return response;
  } catch (e) {
    dispatch(
      gettingEntityTypesFailure(
        'Something went wrong while getting entity types!'
      )
    );
  }
};
export const linkProjects = (data) => async (dispatch) => {
  dispatch(linkingProjects());
  try {
    const response = await axios.post(
      `${config.baseUrl}/dashboard/Contractor/link-projects`,
      data
    );
    if (response.status === 200) {
      dispatch(linkingProjectsSuccess(response.data.result));
    }
    return response;
  } catch (e) {
    dispatch(
      linkingProjectsFailure('Something went wrong while linking  projects!')
    );
  }
};
export const getContractorStats =
  (contractorId = '') =>
  async (dispatch) => {
    dispatch(gettingContractorStats());
    try {
      const response = await axios.get(
        `${config.baseUrl}/dashboard/Contractor/stats?contractorId=${contractorId}`
      );
      if (response.status === 200) {
        dispatch(gettingContractorStatsSuccess(response.data.result));
      }
      return response;
    } catch (e) {
      dispatch(
        gettingContractorStatsFailure(
          'Something went wrong while getting  stats!'
        )
      );
    }
  };
export default slice.reducer;
