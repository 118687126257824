import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useSelector } from 'react-redux';
import { contractorReducer } from 'src/redux/contractors';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      fontSize: '0.8rem', // change the font size to make the text smaller
      padding: theme.spacing(1), // adjust the padding to make the text field smaller
      // width: '10ch', // set the width to make the text field smaller
    },
    '& .MuiInputLabel-root': {
      fontSize: '0.6rem', // change the font size to make the label smaller
    },
  },
  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      maxWidth: '1200px !important',
    },
  },
  buttonGreen: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  padRight: {
    paddingRight: '0.75rem',
  },
  padLeft: {
    paddingLeft: '0.75rem',
  },
}));

const TopCard = ({ val, index }) => {
  let classes = useStyles();

  return (
    <Grid
      container
      style={{
        marginBottom: '1em',
      }}
      alignItems="center"
    >
      <Card
        elevation={6}
        style={{
          borderRadius: 10,
          width: '100%',
          cursor: 'pointer',
          boxShadow: '0px 6px 10px 0px #DBE9F680',
        }}
      >
        <CardContent style={{ padding: '10px' }}>
          <Grid
            container
            justifyContent="space-between"
            item
            xs={12}
            alignItems="center"
          >
            <Grid item>
              <Grid item xs>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: 12,
                    color: '#B2BEC7',
                  }}
                >
                  {val.name}
                </Typography>
              </Grid>
              <Grid
                container
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                item
                xs={12}
              >
                <Grid item xs={12}>
                  <Typography
                    style={{
                      fontFamily: 'Roboto',
                      fontSize: 20,
                      fontWeight: '500',
                    }}
                  >
                    {val.value}
                  </Typography>
                </Grid>
              </Grid>
              <Grid>
                <Typography
                  style={{
                    fontFamily: 'Roboto',
                    fontSize: 9,
                    fontWeight: '500',
                    color: '#B2BEC7',
                  }}
                >
                  {val.stats}
                </Typography>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container justify="center" alignItems="center">
                <img
                  src={val.img}
                  alt="logo1"
                  // style={{ width: '30px' }}
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default TopCard;
