// Entry point app
import 'react-perfect-scrollbar/dist/css/styles.css';
import React, { Suspense, useState, useEffect } from 'react';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import UserContext from './context/UserContext';
import 'react-datepicker/dist/react-datepicker.css';
import Geocode from 'react-geocode';

import './App.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import RoutesConfig from './router/RouteConfig';
//import ReactGA from 'react-ga';
import ReactGA from 'react-ga4';
const TRACKING_ID = 'G-1JS4CYE7J7'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

//push sandbox
//! GOOGLE MAP
Geocode.setApiKey('AIzaSyAp1MrpbYeGqv7hwCFDbPOd7rlrxTazAFQ');
Geocode.enableDebug();
Geocode.setLanguage('en');
export const getLoggedInUserData = () =>
  JSON.parse(localStorage.getItem('userDetails'));

export const userTypes = [
  'SuperAdmin',
  'Developer',
  'Contractor',
  'Supervisor',
  'ProjectManager',
  'SiteAdmin',
  'JobCreator',
  'OnboardingOfficer',
  'PayrollOfficer',
  'Employer',
];
export function breakString(inputString) {
  if (inputString) {
    let brokenString = inputString?.replace(/([a-z])([A-Z])/g, '$1 $2');
    return brokenString;
  }
} //!
export function getAbbreviation(inputString) {
  // Capitalize the first three letters of the input string.
  //package updated
  const abbreviation = inputString?.substring(0, 3).toUpperCase();
  return abbreviation;
}
//change worker job
const App = () => {
  const [userData, setUserData] = useState(getLoggedInUserData());
  useEffect(() => {
    //ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
      title: 'Analytic',
    });
    //console.log(':::::::', window.location.pathname + window.location.search);
  }, []);
  return (
    <Suspense fallback="loading">
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <UserContext.Provider value={[userData, setUserData]}>
          <RoutesConfig />
        </UserContext.Provider>
      </ThemeProvider>
    </Suspense>
  );
};

export default App;
