import { makeStyles } from '@material-ui/core';

export const createContractorStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      // maxWidth: '1800px !important',
    },
  },

  inputFieldsContainer: {
    paddingRight: '1rem',
  },

  commonStyleInput: {
    paddingBottom: '1rem',
  },

  textField: {
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },

  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      color: 'rgba(126, 131, 134, 0.7)',
    },
  },

  buttonGreen: {
    fontSize: 12,
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },

  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },

  buttonPurpleOpacity: {
    fontSize: 12,

    borderRadius: 8,
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  padRight: {
    paddingRight: '0.75rem',
  },

  padLeft: {
    paddingLeft: '0.75rem',
  },

  pointerCursor: {
    cursor: 'pointer',
  },

  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },

  [theme.breakpoints.down('sm')]: {
    inputFieldsContainer: {
      paddingRight: '0px',
    },
  },
}));

export const contractorDetails = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  disabledLink: {
    pointerEvents: 'none',
  },
  buttonGreen: {
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },
  buttonPurple: {
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    borderRadius: '10px',
    padding: '10px 1rem',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: 12,
    fontFamily: 'Roboto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  textField: {
    // marginLeft: theme.spacing(1),
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '15px',
    fontWeight: 400,
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },
  textFieldStyleHeading: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '22px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle1: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#ddd',
    },
  },
  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '15px',
    // color: '#fff',

    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      // fontSize: '15px',
      // color: 'rgba(126, 131, 134, 0.7)',
      // color: '#fff',
    },
  },
  floatingLabelFocusStyle: {
    color: '#F9F9F9',
  },
  input1: {
    borderRadius: '10px',
    // backgroundColor: 'rgba(126, 131, 134, 0.1)',
    backgroundColor: 'transparent',
  },
  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(126, 131, 134, 0.1)',
    // backgroundColor: 'transparent',
  },
  profilePictureContainer: {
    paddingLeft: '1rem',
    paddingTop: '2rem',
    // position: 'absolute',
    // top: '50%',
  },
  greenCard: {
    backgroundColor: '#81B734',
    height: '270px',
    borderRadius: '11px',
    marginRight: '10px',
    // position: 'relative',
  },
  placeholderStyle: {
    '& .MuiFilledInput-root:hover': {
      backgroundColor: 'transparent',
      // Reset on touch devices, it doesn't add specificity
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
      '& .MuiFilledInput-root.Mui-focused': {
        backgroundColor: 'transparent',
      },
    },
  },
  hRow: {
    marginLeft: '25px',
    borderLeft: '1px solid rgba(200, 200, 200, 1)',
    padding: '10px 0',
    height: '40px',
  },
  skillAndAdharFieldContainer: {
    paddingLeft: '4rem',
    paddingTop: '2rem',
    // border: '2px solid red',
  },
  whiteFieldContainer: {
    paddingLeft: '1rem',
    paddingTop: '5rem',
  },
  headerCellText1: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '12px',
  },
  [theme.breakpoints.down('sm')]: {
    greenCard: {
      backgroundColor: '#81B734',
      height: '550px',
      borderRadius: '11px',
      // marginRight: '10px',
      // position: 'relative',
    },
    hRow: {
      marginLeft: '15px',
      borderLeft: '1px solid #fff',
      padding: '10px 0',
      height: '30px',
    },
    skillAndAdharFieldContainer: {
      paddingLeft: '1rem',
      paddingTop: '1rem',
    },
    profilePictureContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
      // position: 'absolute',
      // top: '50%',
    },
    whiteFieldContainer: {
      paddingLeft: '0', //'5.5rem',
      paddingTop: '0 ', //'1rem',
    },
    adharCardFiledContainer: {
      paddingLeft: '5.5rem',
      paddingTop: '1rem',
    },
  },
}));
export const contractorProfileStyle = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },

  modal: {
    '& .MuiDialog-container .MuiDialog-paperWidthLg': {
      // maxWidth: '1800px !important',
    },
  },

  inputFieldsContainer: {
    paddingRight: '1rem',
  },

  commonStyleInput: {
    paddingBottom: '1rem',
  },

  textField: {
    paddingRight: '1.5rem',
    borderRadius: '10px',
    fontFamily: 'Roboto',
    fontSize: '12px',
    fontWeight: 400,
  },

  textFieldStyle: {
    fontWeight: 400,
    fontFamily: 'Roboto',
    fontSize: '12px',
    '&::placeholder': {
      fontWeight: 400,
      fontFamily: 'Roboto',
      fontSize: '12px',
      color: 'rgba(126, 131, 134, 0.7)',
    },
  },
  commonStyle: {
    '& .MuiInputBase-root.Mui-disabled': {
      backgroundColor: 'rgba(221, 221, 221, 0.7)', // Change background color for disabled state
      color: '#444 ', // Change text color for readability
    },
  },
  buttonGreen: {
    fontSize: 12,
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },

  buttonPurple: {
    fontSize: 12,
    padding: '15px 45px',
    borderRadius: 8,
    color: theme.palette.common.white,
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },

  buttonPurpleOpacity: {
    fontSize: 12,

    borderRadius: 8,
    color: '#986FEF',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },

  padRight: {
    paddingRight: '0.75rem',
  },

  padLeft: {
    paddingLeft: '0.75rem',
  },

  pointerCursor: {
    cursor: 'pointer',
  },

  input: {
    borderRadius: '10px',
    backgroundColor: 'rgba(249, 249, 249, 1)',
  },

  [theme.breakpoints.down('sm')]: {
    inputFieldsContainer: {
      paddingRight: '0px',
    },
  },
}));

export const contractorWorkHistoryTableStyle = makeStyles((theme) => ({
  border: {
    padding: '1rem 10px',
    borderRadius: '10px',
    position: 'relative',
    margin: '10px 0',
    background:
      'linear-gradient(white, white) padding-box, linear-gradient(45deg, #81B734, #A179F2) border-box',
    border: '1px solid transparent',
  },
  paddingBottom: {
    paddingBottom: '1rem',
  },
  [theme.breakpoints.down('sm')]: {
    inputFieldsContainer: {
      paddingRight: '0px',
    },
  },
}));
