import React, { useState, useEffect, useRef, useCallback } from 'react';

import {
  Button,
  Grid,
  makeStyles,
  TextField,
  Typography,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import useOnclickOutside from 'react-cool-onclickoutside';

import { useSelector, useDispatch } from 'react-redux';
import WorkIcon from '@material-ui/icons/Work';
import toast, { Toaster } from 'react-hot-toast';

import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete';
//!
import {
  jobReducer,
  getJobs,
  updateJob,
  getSkills,
  selectJob,
} from '../../redux/jobs';
import { getProjects, projectReducer } from 'src/redux/projects';
import * as yup from 'yup';
import { useFormik } from 'formik';
import VideocamIcon from '@material-ui/icons/Videocam';
import AttachmentIcon from '@material-ui/icons/Attachment';
import MicNoneIcon from '@material-ui/icons/MicNone';
import Geocode from 'react-geocode';
import moment from 'moment/moment';
import { importFileandPreview } from '@rajesh896/video-thumbnails-generator';

import CloseIcon from '@material-ui/icons/Close';
import { useNavigate } from 'react-router';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {
  KeyboardTimePicker,
  MuiPickersUtilsProvider,
  TimePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { Divider } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import GlobalRow from './components/GlobalRow';
import JobRowItem from './components/JobRowItem';
//!D

import AddBoxIcon from '@material-ui/icons/AddBox';
import Page from 'src/components/Page';
const useStyles = makeStyles((theme) => ({
  listSection: {
    backgroundColor: 'inherit',
    margin: '5px',
    cursor: 'pointer',
  },

  ul: {
    backgroundColor: 'inherit',
  },
  root: {
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  buttonGreen: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#81B734',
    '&:hover': {
      backgroundColor: '#81B734',
    },
  },

  buttonPurple: {
    color: theme.palette.common.white,
    fontFamily: 'Roboto',
    backgroundColor: '#A179F2',
    '&:hover': {
      backgroundColor: '#A179F2',
    },
  },
  buttonPurpleOpacity: {
    color: '#986FEF',
    fontFamily: 'Roboto',
    backgroundColor: 'rgba(161, 120, 242, 0.20)',
    '&:hover': {
      backgroundColor: 'rgba(161, 120, 242, 0.20)',
    },
  },
  headerCellText: {
    fontSize: '11px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    lineHeight: '13.75px',
  },
  headerCellText1: {
    fontSize: '10px',
    fontWeight: 600,
    fontFamily: 'Roboto',
    color: '#ADBAC3',
  },
  textField: {
    width: '100%',
  },
  input: {
    display: 'none',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  hover: {
    ':hover': {
      backgroundColor: '#A179F2',
    },
  },

  inputFieldGridItem: {
    paddingRight: '1.2rem',
    // border: '1px solid red',
  },
  heading: {
    fontFamily: 'Roboto',
    fontWeight: '500',
  },
  imagePlaceHolder: {
    width: '335px',
    height: '280px',
  },
  commonStyles: {
    fontFamily: 'Roboto',
  },
  audioVideoTitle: {
    color: 'rgba(173, 186, 195, 1)',
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 600,
  },
  audioVideoBoxes: {
    height: '45px',
    border: '1px dashed green',
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  audioVideoBoxesIcon: {
    color: 'rgba(129, 183, 52, 1)',
    backgroundColor: 'rgba(129, 183, 52, 0.2)',
    padding: '5px',
    borderRadius: ' 4px',
    marginLeft: '5px',
  },
  cardRoot: {
    backgroundColor: theme.palette.background.dark,
  },
  addNewJob: {
    backgroundColor: '#ddd',
    width: '150px',
    textTransform: 'capitalize',
    border: '1px dashed #333',
  },
  [theme.breakpoints.down('sm')]: {
    inputFieldGridItem: {
      paddingRight: '0px',
    },
  },
  // scopeOfWork: { width: '15%' },
  // [theme.breakpoints.down('md')]: {
  //   scopeOfWork: { width: '96%' }, // Custom width on md screens and larger
  // },
}));

function SignleJobCreateForm(props) {
  return (
    <>
      <Grid item container>
        <Grid container item xs={12} alignItems="center">
          <Grid
            item
            md={6}
            xs={12}
            className={props.classes.inputFieldGridItem}
          >
            <Typography
              style={{
                fontSize: 12,
                color: '#ADBAC3',
                marginBottom: 5,
                fontFamily: 'Roboto',
              }}
            >
              PROJECT NAME
            </Typography>
            <TextField
              select
              size="small"
              style={{
                marginBottom: '1rem',
                fontFamily: 'Roboto',
              }} // placeholder='----'
              variant="outlined"
              fullWidth
              disabled={props.selectedJob ? true : false} // style={{ width: "300px" }}
              id="projectId"
              name="projectId"
              value={props.formik.values.projectId}
              onChange={(data) => props.handleProjectChange(data.target.value)}
              error={
                props.formik.touched.projectId &&
                Boolean(props.formik.errors.projectId)
              }
              helperText={
                props.formik.touched.projectId && props.formik.errors.projectId
              }
            >
              <MenuItem value="Select Project">Select Project</MenuItem>

              {props.projectsList?.map((project) => (
                <MenuItem
                  key={project.projectId}
                  value={props.selectedJob ? project?.projectId : project}
                  style={{
                    fontFamily: 'Roboto',
                  }} // onClick={() => handleProjectChange(project)}
                >
                  {project.name}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              style={{
                fontSize: 12,
                color: '#ADBAC3',
                marginBottom: 5,
                fontFamily: 'Roboto',
              }}
            >
              REQUIRED WORKERS
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth
              type="number"
              style={{
                marginBottom: '1rem',
              }}
              id="requiredWorkers"
              name="requiredWorkers"
              value={props.formik.values.requiredWorkers}
              onChange={props.formik.handleChange}
              error={
                props.formik.touched.requiredWorkers &&
                Boolean(props.formik.errors.requiredWorkers)
              }
              helperText={
                props.formik.touched.requiredWorkers &&
                props.formik.errors.requiredWorkers
              }
              inputProps={{
                style: {
                  fontFamily: 'Roboto',
                },
              }}
            />
          </Grid>

          <Grid
            item
            md={6}
            xs={12}
            className={props.classes.inputFieldGridItem}
          >
            <Typography
              style={{
                fontSize: 12,
                color: '#ADBAC3',
                marginBottom: 5,
                fontFamily: 'Roboto',
              }}
            >
              SUPERVISOR NAME
            </Typography>
            <TextField
              size="small"
              variant="outlined"
              fullWidth // type="number"
              style={{
                marginBottom: '1rem',
              }}
              id="supervisorName"
              name="supervisorName"
              value={props.formik.values.supervisorName}
              onChange={props.formik.handleChange}
              error={
                props.formik.touched.supervisorName &&
                Boolean(props.formik.errors.supervisorName)
              }
              helperText={
                props.formik.touched.supervisorName &&
                props.formik.errors.supervisorName
              }
              inputProps={{
                style: {
                  fontFamily: 'Roboto',
                },
              }}
            />
          </Grid>
          <Grid
            item
            md={6}
            xs={12}
            style={{
              height: '82.2px',
            }}
          >
            <Typography
              style={{
                fontSize: 12,
                color: '#ADBAC3',
                marginBottom: 5,
                fontFamily: 'Roboto',
              }}
            >
              SUPERVISOR CONTACT
            </Typography>

            <Grid
              item
              style={{
                height: '40px',
              }}
            >
              <PhoneInput
                style={{
                  width: '100%',
                  height: '100%',
                  marginBottom: '1rem',
                  border: `1px solid ${
                    props.formik.touched.contactNumber &&
                    props.formik.errors.contactNumber
                      ? 'red'
                      : '#999'
                  }`,
                }}
                id="contactNumber"
                name="contactNumber"
                value={props.formik.values.contactNumber}
                onChange={(value) =>
                  props.formik.setFieldValue('contactNumber', value)
                }
                country={'in'}
                onlyCountries={['in']}
                countryCodeEditable={false} // value={this.state.phone}
                // onChange={phone => this.setState({ phone })}
              />
              {props.formik.touched.contactNumber &&
                props.formik.errors.contactNumber && (
                  <small
                    style={{
                      fontSize: '12px',
                      margin: '0 14px',
                      color: 'red',
                    }}
                  >
                    Contact Number is required
                  </small>
                )}
            </Grid>
          </Grid>

          <Grid item container>
            <Grid
              item
              md={6}
              xs={12}
              className={props.classes.inputFieldGridItem}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                SKILL SET
              </Typography>
              <TextField
                select
                size="small"
                style={{
                  marginBottom: '1rem',
                  fontFamily: 'Roboto',
                }} // placeholder='----'
                disabled={props.selectedJob ? true : false}
                variant="outlined"
                fullWidth // style={{ width: "300px" }}
                id="skillId"
                name="skillId"
                value={props.formik.values.skillId}
                onChange={(e) => {
                  props.formik.setFieldValue('skillId', e.target.value);
                }}
                error={
                  props.formik.touched.skillId &&
                  Boolean(props.formik.errors.skillId)
                }
                helperText={
                  props.formik.touched.skillId && props.formik.errors.skillId
                }
              >
                <MenuItem value="Select Skill">Select Skill</MenuItem>

                {props.skillsList?.map((skill) => (
                  <MenuItem
                    key={skill.skillId}
                    value={skill}
                    style={{
                      fontFamily: 'Roboto',
                    }}
                  >
                    {skill.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            {/* {subSkillFound && ( */}
            {/* {formik.values.skillId?.name === "Machine Operator" ? (
             <Grid
               item
               md={6}
               xs={12}
               // className={classes.inputFieldGridItem}
             >
               <Typography
                 style={{
                   fontSize: 12,
                   color: "#ADBAC3",
                   marginBottom: 5,
                   fontFamily: "Roboto",
                 }}
               >
                 SKILL SET
               </Typography>
               <TextField
                 select
                 size="small"
                 style={{ marginBottom: "1rem", fontFamily: "Roboto" }}
                 // placeholder='----'
                 disabled={selectedJob ? true : false}
                 variant="outlined"
                 fullWidth
                 // style={{ width: "300px" }}
                 id="skillTypeId"
                 name="skillTypeId"
                 value={formik.values.skillTypeId}
                 onChange={(e) => {
                   formik.setFieldValue("skillTypeId", e.target.value);
                 }}
                 error={
                   formik.touched.skillTypeId &&
                   Boolean(formik.errors.skillTypeId)
                 }
                 helperText={
                   formik.touched.skillTypeId && formik.errors.skillTypeId
                 }
               >
                 <MenuItem value="Select Skill">
                   Select Skill Level
                 </MenuItem>
                   {[
                   "Bobcat",
                   "Bulldozer",
                   "Tower Crane",
                   "Tough Rider",
                   "Signalman",
                   "Concrete Mixer",
                   "Forklift",
                   "Tractor",
                   "JCB",
                   "RSP -Rope Suspended Platform",
                     "Driver",
                   "Hoist Operator",
                   "Sewage Treatment Operator",
                 ]?.map((subSkill) => (
                   <MenuItem
                     key={subSkill}
                     value={subSkill}
                     style={{ fontFamily: "Roboto" }}
                   >
                     {subSkill}
                   </MenuItem>
                 ))}
               </TextField>
             </Grid>
            ) : ( */}
            <Grid
              item
              md={6}
              xs={12} // className={ classes.inputFieldGridItem }
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                SKILL LEVEL
              </Typography>

              <FormControl size="small">
                <RadioGroup
                  row
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue={props.formik.values.skillTypeId}
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    value="Supervisor"
                    control={
                      <Radio
                        disabled={props.selectedJob ? true : false}
                        onChange={(e) =>
                          props.handleSkillCheckbox('Supervisor')
                        }
                      />
                    }
                    label="Supervisor"
                  />
                  <FormControlLabel
                    value="Skilled"
                    control={
                      <Radio
                        disabled={props.selectedJob ? true : false}
                        onChange={(e) => props.handleSkillCheckbox('Skilled')}
                      />
                    }
                    label="Skilled"
                  />
                  <FormControlLabel
                    value="Helper"
                    control={
                      <Radio
                        disabled={props.selectedJob ? true : false}
                        onChange={(_e) => props.handleSkillCheckbox('Helper')}
                      />
                    }
                    label="Helper"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {/* // )} */}
            {/* )} */}
            <Grid
              item
              md={6}
              xs={12}
              className={props.classes.inputFieldGridItem}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                SCOPE OF WORK
              </Typography>
              <TextField
                size="small"
                style={{
                  marginBottom: '1rem',
                }}
                id="description"
                name="description"
                value={props.formik.values.description}
                onChange={props.formik.handleChange}
                error={
                  props.formik.touched.description &&
                  Boolean(props.formik.errors.description)
                }
                helperText={
                  props.formik.touched.description &&
                  props.formik.errors.description
                }
                variant="outlined"
                fullWidth // type='number'
                inputProps={{
                  style: {
                    fontFamily: 'Roboto',
                  },
                }} // style={{ width: "300px" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                JOB TYPE
              </Typography>{' '}
              <FormControl component="fieldset">
                <RadioGroup
                  row // defaultValue={selectedJob?.isNMR}
                  value={props.formik.values?.isNMR}
                  onChange={(e) => {
                    props.formik.setFieldValue(
                      'isNMR',
                      e.target.value == 'true' ? true : false
                    );
                  }}
                >
                  <FormControlLabel
                    value={true}
                    control={
                      <Radio // onChange={(e) =>
                      // formik.setFieldValue("isPrivate", false)
                      // }
                      />
                    }
                    label="NMR"
                  />
                  <FormControlLabel // value={false}
                    value={false}
                    control={
                      <Radio // onChange={(e) =>
                      // formik.setFieldValue("isPrivate", true)
                      // }
                      />
                    }
                    label="PRW"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid
              item
              md={6}
              xs={12}
              className={props.classes.inputFieldGridItem}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                DAILY WAGE ₹
              </Typography>
              <TextField
                disabled={props.selectedJob ? true : false}
                size="small"
                style={{
                  marginBottom: '1rem',
                }}
                id="dailyWage"
                name="dailyWage"
                value={props.formik.values.dailyWage}
                onChange={props.formik.handleChange}
                error={
                  props.formik.touched.dailyWage &&
                  Boolean(props.formik.errors.dailyWage)
                }
                helperText={
                  props.formik.touched.dailyWage &&
                  props.formik.errors.dailyWage
                }
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  style: {
                    fontFamily: 'Roboto',
                  },
                }} // style={{ width: "300px" }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                MAN DAYS
              </Typography>

              <TextField
                size="small"
                variant="outlined" // type='datetime-local'
                type="number"
                className={props.classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                style={{
                  marginBottom: '1rem',
                }}
                id="manDays"
                name="manDays"
                value={props.formik.values.manDays}
                onChange={props.formik.handleChange}
                error={
                  props.formik.touched.manDays &&
                  Boolean(props.formik.errors.manDays)
                }
                helperText={
                  props.formik.touched.manDays && props.formik.errors.manDays
                }
                inputProps={{
                  style: {
                    fontFamily: 'Roboto',
                  },
                }}
              />
            </Grid>
          </Grid>

          <Grid item container>
            <Grid
              item
              md={6}
              xs={12}
              className={props.classes.inputFieldGridItem}
            >
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                START DATE
              </Typography>
              <TextField
                size="small"
                id="startDate"
                disabled={props.selectedJob ? true : false}
                style={{
                  marginBottom: '1rem',
                }}
                name="startDate"
                value={props.formik.values.startDate}
                onChange={props.formik.handleChange}
                error={
                  props.formik.touched.startDate &&
                  Boolean(props.formik.errors.startDate)
                }
                helperText={
                  props.formik.touched.startDate &&
                  props.formik.errors.startDate
                }
                variant="outlined" // type='datetime-local'
                type="date"
                className={props.classes.textField}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  style: {
                    fontFamily: 'Roboto',
                  },
                  min: !props.selectedJob && moment().format('YYYY-MM-DD'),
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                REPORTING TIME
              </Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <TimePicker
                  margin="normal"
                  KeyboardButtonProps={{
                    'aria-label': 'change time',
                  }}
                  size="small"
                  style={{
                    marginBottom: '1rem',
                    fontFamily: 'Roboto',
                  }}
                  variant="outlined"
                  className={props.classes.textField}
                  id="reportingTime"
                  name="reportingTime"
                  value={props.formik.values.reportingTime}
                  onChange={(time) => {
                    props.formik.setFieldValue('reportingTime', time);
                  }}
                  error={
                    props.formik.touched.reportingTime &&
                    Boolean(props.formik.errors.reportingTime)
                  } // disabled
                  helperText={
                    props.formik.touched.reportingTime &&
                    props.formik.errors.reportingTime
                  }
                />
              </MuiPickersUtilsProvider>
              {/* <TextField
               size="small"
               style={{ marginBottom: "1rem", fontFamily: "Roboto" }}
               variant="outlined"
               // type='datetime-local'
               type="time"
               className={classes.textField}
               inputProps={{
                 step: 300, // 5 minutes interval
               }}
               InputLabelProps={
                 {
                   // shrink: true,
                 }
               }
               id="reportingTime"
               name="reportingTime"
               value={formik.values.reportingTime}
               onChange={formik.handleChange}
               error={
                 formik.touched.reportingTime &&
                 Boolean(formik.errors.reportingTime)
               }
               helperText={
                 formik.touched.reportingTime &&
                 formik.errors.reportingTime
               }
              /> */}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container>
        <Grid item md={6} xs={12} className={props.classes.inputFieldGridItem}>
          <Typography
            style={{
              fontSize: 12,
              color: '#ADBAC3',
              marginBottom: 5,
              fontFamily: 'Roboto',
            }}
          >
            JOB STATUS
          </Typography>{' '}
          <FormControl component="fieldset">
            <RadioGroup
              row
              defaultValue={props.formik.values.isPrivate}
              value={props.formik.values.isPrivate}
              onChange={(e) => {
                props.formik.setFieldValue(
                  'isPrivate',
                  e.target.value == 'true' ? true : false
                );
              }}
            >
              <FormControlLabel // value={false}
                value={false}
                control={
                  <Radio // onChange={(e) =>
                  // formik.setFieldValue("isPrivate", true)
                  // }
                  />
                }
                label="Private"
              />
              <FormControlLabel
                value={true}
                control={
                  <Radio // onChange={(e) =>
                  // formik.setFieldValue("isPrivate", false)
                  // }
                  />
                }
                label="Public"
              />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid container md={6} xs={12} alignItems="center">
          <Grid
            item
            container
            justify="flex-start"
            alignItems="center"
            className={props.classes.inputFieldGridItem}
          >
            <Grid item xs={12}>
              <Typography
                style={{
                  fontSize: 12,
                  color: '#ADBAC3',
                  marginBottom: 5,
                  fontFamily: 'Roboto',
                }}
              >
                FACILITIES
              </Typography>
            </Grid>
            <Grid item>
              {' '}
              <FormControlLabel
                control={
                  <Checkbox // checked={jason}
                    color="primary"
                    disabled={props.selectedJob ? true : false}
                    checked={props.formik.values.isFood}
                    id="isFood"
                    name="isFood"
                    value={props.formik.values.isFood}
                    onChange={props.formik.handleChange}
                  />
                }
                label="Food"
              />
            </Grid>
            <Grid item>
              {' '}
              <FormControlLabel
                control={
                  <Checkbox // checked={jason}
                    color="primary"
                    disabled={props.selectedJob ? true : false}
                    checked={props.formik.values.isAccomodation}
                    id="isAccomodation"
                    name="isAccomodation"
                    value={props.formik.values.isAccomodation}
                    onChange={props.formik.handleChange}
                  />
                }
                label="Accomodation"
              />
            </Grid>
          </Grid>
        </Grid>
        {/* <Grid
        item
        xs={4}
        className={classes.inputFieldGridItem}
        style={{ paddingRight: '2.6rem' }}
        >
        <Typography
        style={{
        fontSize: 12,
        color: '#ADBAC3',
        marginBottom: 5,
        fontFamily: 'Roboto',
        }}
        >
        REQUIRED SKILL LEVEL
        </Typography>
        <TextField
        variant='outlined'
        style={{ marginBottom: '1rem' }}
        type='number'
        className={classes.textField}
        id='skillLevel'
        name='skillLevel'
        InputProps={{
        inputProps: { min: 0, max: 100 },
        style: { fontFamily: 'Roboto' },
        }}
        value={formik.values.skillLevel}
        onChange={formik.handleChange}
        error={
        formik.touched.skillLevel && Boolean(formik.errors.skillLevel)
        }
        helperText={
        formik.touched.skillLevel && formik.errors.skillLevel
        }
        />
        </Grid>
        <Grid item xs={4} className={classes.inputFieldGridItem}>
        <Typography
        style={{
        fontSize: 12,
        color: '#ADBAC3',
        marginBottom: 5,
        fontFamily: 'Roboto',
        }}
        >
        REQUIRED RATING LEVEL
        </Typography>
        <TextField
        style={{ marginBottom: '1rem' }}
        variant='outlined'
        fullWidth
        type='number'
        id='rating'
        name='rating'
        value={formik.values.rating}
        onChange={formik.handleChange}
        error={formik.touched.rating && Boolean(formik.errors.rating)}
        helperText={formik.touched.rating && formik.errors.rating}
        // style={{ width: "300px" }}
        inputProps={{ style: { fontFamily: 'Roboto' } }}
        InputProps={{
        inputProps: { min: 0, max: 5 },
        style: { fontFamily: 'Roboto' },
        }}
        />
        </Grid> */}
      </Grid>
      <Grid container xs={12}>
        <Grid item xs={12} className={props.classes.inputFieldGridItem}>
          <Typography
            style={{
              fontSize: 12,
              color: '#ADBAC3',
              marginBottom: 5,
              fontFamily: 'Roboto',
            }}
          >
            LOCATION
          </Typography>
          <div ref={props.ref}>
            <TextField
              size="small"
              style={{
                marginBottom: '1rem',
              }}
              variant="outlined"
              fullWidth
              value={props.formik.values?.cityName ?? 'This has 0 lat, lng'} // onChange={handleInput}
              disabled
              error={
                props.formik.touched.latitude &&
                Boolean(props.formik.errors.latitude)
              }
              helperText={
                props.formik.touched.latitude && props.formik.errors.latitude
              }
              placeholder="Enter Job Location"
              inputProps={{
                style: {
                  fontFamily: 'Roboto',
                },
              }}
            />
          </div>
        </Grid>
      </Grid>

      <Grid container item>
        <Grid item xs={12}>
          <Typography
            variant="h5"
            component="p"
            className={props.classes.commonStyles}
            style={{
              marginBottom: '.5rem',
              marginTop: '.5rem',
            }}
          >
            {' '}
            Job Instructions
          </Typography>
        </Grid>
        <Grid item md={6} xs={12} className={props.classes.inputFieldGridItem}>
          <Typography
            variant="p"
            component="p"
            className={props.classes.audioVideoTitle}
            style={{
              marginBottom: '5px',
            }}
          >
            Upload Video File
          </Typography>
          <div>
            <input
              disabled={props.selectedJob ? true : false}
              accept="video/*"
              className={props.classes.input}
              style={{
                display: props.video && 'none',
              }} // multiple
              type="file"
              id="video"
              name="video"
              value={props.formik.values.video} // onChange={formik.handleChange}
              onChange={props.handleVideo}
              error={
                props.formik.touched.video && Boolean(props.formik.errors.video)
              }
              helperText={
                props.formik.touched.video && props.formik.errors.video
              }
            />
            <label htmlFor="video">
              {/* <Button
              fullWidth
              variant='contained'
              color='primary'
              component='span'
              startIcon={<AudiotrackIcon />}
              >
              Upload Audio
              </Button> */}
              <div className={props.classes.audioVideoBoxes}>
                <AttachmentIcon
                  fontSize="large"
                  className={props.classes.audioVideoBoxesIcon}
                />
                <p
                  style={{
                    color: props.video ? 'black' : 'rgba(173, 186, 195, 1)',
                    fontFamily: 'Roboto',
                  }}
                  className={props.video && 'dots'}
                >
                  {props.video ? (
                    <>{props.video.name}</>
                  ) : !props.video && props.selectedMedia?.video?.fileName ? ( // <a
                    // 	href={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_CLASSIFIED}${selectedMedia?.video?.url}`}
                    // 	target='_blank'
                    // 	style={{ fontFamily: 'Roboto' }}
                    // >
                    <p
                      style={{
                        color: 'rgba(173, 186, 195, 1)',
                        fontFamily: 'Roboto',
                      }}
                      className="dots"
                    >
                      {props.selectedMedia?.video?.fileName}
                    </p> // </a>
                  ) : (
                    'Browse from gallery to attach video file'
                  )}
                </p>
                {props.video && (
                  <CloseIcon
                    style={{
                      color: props.video ? 'black' : 'rgba(173, 186, 195, 1)',
                    }}
                    onClick={() => props.setVideo(null)}
                  />
                )}
                <VideocamIcon
                  fontSize="large"
                  style={{
                    color: 'rgba(129, 183, 52, 1)',
                    marginRight: '5px',
                  }}
                />
              </div>
              <p
                style={{
                  color: 'red',
                }}
              >
                {props.formik.touched.audio && props.formik.errors.audio}
              </p>
            </label>
          </div>
        </Grid>
        <Grid item md={6} xs={12}>
          <Typography
            variant="p"
            component="p"
            className={props.classes.audioVideoTitle}
            style={{
              marginBottom: '5px',
            }}
          >
            Upload Audio File{' '}
          </Typography>
          <div>
            <input
              disabled={props.selectedJob ? true : false}
              accept="audio/*"
              className={props.classes.input} // multiple
              type="file"
              id="audio"
              name="audio"
              value={props.formik.values.audio}
              onChange={props.handleAudio}
              error={
                props.formik.touched.audio && Boolean(props.formik.errors.audio)
              }
              helperText={
                props.formik.touched.audio && props.formik.errors.audio
              }
            />
            <label htmlFor="audio">
              {/* <Button
              fullWidth
              variant='contained'
              color='primary'
              component='span'
              startIcon={<VideoCallIcon />}
              >
              Upload Video
              </Button> */}
              <div className={props.classes.audioVideoBoxes}>
                <AttachmentIcon
                  fontSize="large"
                  className={props.classes.audioVideoBoxesIcon}
                />
                <p
                  style={{
                    color: props.audio ? 'black' : 'rgba(173, 186, 195, 1)',
                    fontFamily: 'Roboto',
                  }}
                  className="dots"
                >
                  {props.audio ? (
                    props.audio.name
                  ) : !props.audio && props.selectedMedia?.audio?.fileName ? ( // <a
                    // 	href={`${process.env.REACT_APP_PATH_PRODUCTION_IMAGE_CLASSIFIED}${selectedMedia?.audio?.url}`}
                    // 	target='_blank'
                    // 	style={{ fontFamily: 'Roboto' }}
                    // >
                    <p
                      style={{
                        color: 'rgba(173, 186, 195, 1)',
                        fontFamily: 'Roboto',
                      }}
                      className="dots"
                    >
                      {props.selectedMedia?.audio?.fileName}
                    </p> // </a>
                  ) : (
                    'Browse from gallery to attach audio file'
                  )}
                </p>
                {props.audio && (
                  <CloseIcon
                    style={{
                      color: props.video ? 'black' : 'rgba(173, 186, 195, 1)',
                    }}
                    onClick={() => props.setAudio(null)}
                  />
                )}
                <MicNoneIcon
                  fontSize="large"
                  style={{
                    color: 'rgba(129, 183, 52, 1)',
                    marginRight: '5px',
                  }}
                />
              </div>
              <p
                style={{
                  color: 'red',
                }}
              >
                {props.formik.touched.video && props.formik.errors.video}
              </p>
            </label>
          </div>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        justify="flex-end"
        style={{
          marginTop: '1.5rem',
          marginBottom: 13,
        }}
      >
        {/* <Grid container justify="space-between" item xs={4}> */}
        <Grid
          item
          style={{
            marginRight: '1em',
          }}
        >
          <Button // onClick={() => {
            // 	setShowCard(true);
            // 	setCreateNewProject(false);
            // }}
            disabled={props.loading}
            fullWidth
            className={props.classes.buttonGreen}
            style={{
              marginRight: 15,
            }}
            type="submit"
            startIcon={<WorkIcon />}
          >
            {props.selectedJob ? 'Update' : 'Create'}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={props.classes.buttonPurple}
            fullWidth
            onClick={() => {
              if (props.selectedJob) {
                return props.navigate('..detail');
              }

              props.navigate('..');
              props.dispatch(selectJob(null));
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

const CreateJob = () => {
  const [media, setMedia] = useState({
    audio: '',
    video: '',
  });
  const [view, setView] = useState(false);
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [videoUrl, setVideoUrl] = useState('');
  const [videoThumb, setVideoThumb] = useState('');
  const [thumbnails, setThumbnails] = useState([]);
  const [subSkillFound, setSubSkillFound] = useState(false);
  const [rowValidationStates, setRowValidationStates] = useState({});

  const validateRowFields = (rowId, isValid) => {
    setRowValidationStates((prev) => ({
      ...prev,
      [rowId]: isValid,
    }));
  };

  const validateAllRows = () => {
    const allValid = Object.values(rowValidationStates).every(Boolean);
    if (!allValid) {
      console.error('Some rows have invalid fields!');
    }
    return allValid;
  };

  const [listOfRow, setlistOfRow] = useState([
    {
      id: uuidv4(),
      skillId: 'Select Skill',
      skillTypeId: 'Supervisor',
      isNMR: true,
      requiredWorkers: '',
      manDays: '',
      description: '',
      dailyWage: '',
    },
  ]);

  const refs = useRef({
    video: null,
    loader: null,
    numberInput: null,
    thumbButton: null,
  });
  const [selectedMedia, setSelectedMedia] = useState({
    audio: null,
    video: null,
  });
  const [markerObj, setMarkerObj] = useState(null);
  const [defaultProps, setDefaultProps] = useState({
    center: {
      lat: formik?.values.latitude,
      lng: formik?.values.longitude,
    },
    zoom: 11,
  });

  const reff = useRef(null);
  const [map, setMap] = useState();

  useEffect(() => {
    if (reff.current && !map) {
      setMap(new window.google.maps.Map(reff.current, {}));
    }
  }, [reff, map]);

  //! INSTANCES

  let dispatch = useDispatch();
  const classes = useStyles();
  let navigate = useNavigate();
  const { selectedJob } = useSelector(jobReducer);

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 1000,
  });

  //! LIFE CYCLE

  useEffect(() => {
    setSelectedMedia({
      ...selectedMedia,
      audio: selectedJob?.jobMedia.filter(
        (item) => item.mediaTypeId === 'Audio'
      )[0],
      video: selectedJob?.jobMedia.filter(
        (item) => item.mediaTypeId === 'Video'
      )[0],
    });
    if (video) {
      importFileandPreview(video).then((res) => {
        setVideoUrl(res);
      });
      setVideoThumb('');
      setThumbnails([]);
      if (refs.current.video) {
        refs.current.video.style.transform = 'scale(1)';
      }

      if (refs.current.numberInput) {
        refs.current.numberInput.style.display = 'block';
      }
      if (refs.current.thumbButton) {
        refs.current.thumbButton.style.display = 'block';
      }
    }
  }, [video]);
  //!
  useEffect(() => {
    if (selectedJob) {
      Geocode.fromLatLng(selectedJob?.latitude, selectedJob?.longitude).then(
        (response) => {
          const address = response.results[0].formatted_address;
          // setLocation(address);
          return setValue(address);
        },
        (error) => {
          // console.error('error', error.message);
          return setValue(error.message);
        }
      );
    }
  }, []);

  //! SELECTORS
  const { loading, skillsList } = useSelector(jobReducer);
  const { projectsList } = useSelector(projectReducer);

  //! METHODS
  //* 1. FORMIK
  // const validationSchema = yup.array().of(
  //   yup.object().shape({
  //     projectId: selectedJob?.projectId
  //       ? yup.number().required('Project is required')
  //       : yup.object().required('Project is required'),
  //     startDate: yup.string().required('Start date is required'),
  //     reportingTime: yup.string().required('Reporting time is required'),

  //     // endDate: yup.string().required('End date is required'),
  //     // latitude: yup.string().required("Location is required"),
  //     // longitude: yup.string().required('Longitude is required'),
  //     // skillId: yup.string().required('Skill is required'),
  //     // skillLevel: yup.number().required('Skill level is required'),
  //     // rating: yup.number().required('Rating is required'),
  //     // requiredWorkers: yup.number().required('Required workers is required'),
  //     // dailyWage: yup.number().min(1).required('Daily wage is required'),
  //     // contactNumber: yup.number().required('Contact number is required'),
  //     // manDays: yup.number().required('Man Days are required'),

  //     // image: yup.string().required('Image is required'),
  //     // audio: yup.string().required('Audio is required'),
  //     // video: yup.string().required('Video  is required'),
  //   })
  // );
  const validationSchema = yup.object({
    projectId: selectedJob?.projectId
      ? yup.number().required('Project is required')
      : yup.object().required('Project is required'),
    startDate: yup.string().required('Start date is required'),
    reportingTime: yup.string().required('Reporting time is required'), // Ensures it's not empty
    // .typeError('Invalid date format') // Ensures it's a valid date
    // .min(moment().toDate(), 'Reporting time must be in the future'),
    supervisorName: yup.string().required('Supervisor name is required'),
    // endDate: yup.string().required('End date is required'),
    // latitude: yup.string().required("Location is required"),
    // longitude: yup.string().required('Longitude is required'),
    // skillId: yup.string().required('Skill is required'),
    // skillLevel: yup.number().required('Skill level is required'),
    // rating: yup.number().required('Rating is required'),
    // requiredWorkers: yup.number().required('Required workers is required'),
    // dailyWage: yup.number().min(1).required('Daily wage is required'),
    // contactNumber: yup.number().required('Contact number is required'),
    // manDays: yup.number().required('Man Days are required'),

    // image: yup.string().required('Image is required'),
    // audio: yup.string().required('Audio is required'),
    // video: yup.string().required('Video  is required'),
  });

  var formik = useFormik({
    initialValues: {
      contractorId: 1,
      startDate: selectedJob?.startDate
        ? moment(selectedJob?.startDate).format('YYYY-MM-DD')
        : '',
      // endDate: selectedJob?.endDate
      // ? moment(selectedJob?.endDate).format('YYYY-MM-DD')
      // : '',
      latitude: selectedJob?.latitude ?? '',
      longitude: selectedJob?.longitude ?? '',
      // skillId: selectedJob?.skillId
      //   ? skillsList?.filter((item) => item.skillId === selectedJob?.skillId)[0]
      // : '',
      skillLevel: selectedJob?.skillLevel ?? 0,
      reportingTime: selectedJob?.reportingTime
        ? moment(selectedJob?.reportingTime).toDate()
        : null,
      // rating: selectedJob?.rating ?? 0,
      // requiredWorkers: selectedJob?.requiredWorkers ?? '',
      // dailyWage: '',
      audio: '',
      video: '',
      projectId:
        projectsList?.filter(
          (item) => item.projectId === selectedJob?.projectId
        )[0]?.projectId ?? '',
      // description: selectedJob?.description ?? '',
      contactNumber: '912100310912',
      // manDays: '',
      isFood: selectedJob?.isFood ?? false,
      isAccomodation: selectedJob?.isAccomodation ?? false,
      // skillTypeId: selectedJob?.skillTypeId ?? 'Supervisor',
      cityName: value,
      isPrivate: selectedJob?.isPrivate ?? false,
      // supervisorName: selectedJob?.supervisorName ?? '',
      // isNMR: selectedJob?.isNMR ?? true,
    },

    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values) => {
      // if (validateAllRows()) {
      //   return;
      // }

      const glovalRowKeys = Object.keys(values);
      const glovalRowValues = Object.values(values);
      const formData = new FormData();
      let loading = toast.loading('Creating Jobs...');

      glovalRowKeys.forEach((key, index) => {
        formData.append(
          key,
          key === 'projectId'
            ? glovalRowValues[index]?.projectId
            : key === 'reportingTime'
            ? moment(glovalRowValues[index]).format('YYYY-MM-DD HH:mm:ss')
            : glovalRowValues[index]
        );
      });

      const updateJobs = async () => {
        try {
          // Create an array of promises using map
          const updatePromises = listOfRow.map(({ id, isHovered, ...rest }) => {
            const rowData = new FormData();

            // Populate rowData with fields
            rowData.append('skillId', rest.skillId.skillId);
            rowData.append('skillTypeId', rest.skillTypeId);
            rowData.append('isNMR', rest.isNMR);
            rowData.append('requiredWorkers', rest.requiredWorkers);
            rowData.append('manDays', rest.manDays);
            rowData.append('description', rest.description);
            rowData.append('dailyWage', rest.dailyWage);

            // Create merged FormData
            const mergedData = new FormData();

            // Append all fields from formData
            formData.forEach((value, key) => {
              mergedData.append(key, value);
            });

            // Append all fields from rowData
            rowData.forEach((value, key) => {
              mergedData.append(key, value);
            });

            // Return the dispatch promise
            return dispatch(updateJob(mergedData));
          });

          // Use Promise.all to execute all updateJob requests concurrently
          const responses = await Promise.all(updatePromises);

          // Check if all responses were successful
          const allSuccess = responses.every((resp) => resp.status === 200);

          if (allSuccess) {
            toast.success('All jobs were created successfully!');
            navigate('..');
            dispatch(getJobs());
          } else {
            toast.error(
              'Some jobs failed to create. Please check and try again.'
            );
          }
        } catch (error) {
          console.error('Error updating jobs:', error);
          toast.error(
            'An error occurred while updating jobs. Please try again.'
          );
        } finally {
          toast.dismiss(loading); // Dismiss loading state
        }
      };

      updateJobs();
    },
  });

  useEffect(() => {
    if (!skillsList) {
      dispatch(getSkills());
    }
    if (!projectsList) {
      dispatch(getProjects());
    }
    // else {
    //   let item = projectsList?.filter(
    //     (item) => item.projectId === selectedJob?.projectId
    //   );
    //   formik.setFieldValue("projectId", item[0]);
    //   console.log("item", item);
    // }
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      if (!formik.values.latitude && !formik.values.longitude) {
        setDefaultProps({
          ...defaultProps,
          center: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
        });
      }
    });
  }, []);
  // 	const handleSelect =
  // 		({ description }) =>
  // 		() => {
  // 			// When user selects a place, we can replace the keyword without request data from API
  // 			// by setting the second parameter to "false"
  // 			setValue(description, false);
  // 			clearSuggestions();
  //
  // 			// console.log('description', description);
  // 			// Get latitude and longitude via utility functions
  // 			getGeocode({ address: description }).then((results) => {
  // 				const { lat, lng } = getLatLng(results[0]);
  // 				formik.setFieldValue('latitude', lat);
  // 				formik.setFieldValue('longitude', lng);
  // 				formik.setFieldValue('cityName', results[0].formatted_address);
  // 			});
  // 		};

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  // const handleInput = (e) => {
  // 	// Update the keyword of the input element
  // 	setValue(e.target.value);
  // 	// setView(false);
  // 	clearSuggestions();
  // };

  // 	const renderSuggestions = () => {
  // 		data.map((suggestion) => {
  // 			const {
  // 				place_id,
  // 				structured_formatting: { main_text, secondary_text },
  // 			} = suggestion;
  //
  // 			return (
  // 				<List className={classes.root} subheader={<li />}>
  // 					{data.map((sectionId) => (
  // 						<li
  // 							key={place_id}
  // 							onClick={handleSelect(suggestion)}
  // 							className={classes.listSection}>
  // 							<strong>{main_text}</strong> <small>{secondary_text}</small>
  // 						</li>
  // 					))}
  // 				</List>
  // 			);
  // 		});
  // 	};

  const handleAudio = (e) => {
    setMedia({
      ...media,
      audio: e.target.files[0],
    });
    setAudio(e.target.files[0]);
  };
  const handleVideo = (e) => {
    // formData.append('video', e.target.files[0]);
    setMedia({
      ...media,
      video: e.target.files[0],
    });
    setVideo(e.target.files[0]);
  };
  // 	const handleProjectImage = (e) => {
  // 		let selectedImage = e.target.files[0];
  // 		formik.setFieldValue('image', selectedImage);
  // 	};
  //
  // 	// console.log('video', video);
  //
  // 	const handleMapClick = (key, childProps) => {
  // 		console.log('key', key);
  // 		console.log('childProps', childProps);
  // 	};
  //
  // 	const AnyReactComponent = ({ text }) => (
  // 		<RoomIcon style={{ color: 'green', fontSize: '2.5rem' }} />
  // 	);

  // const fetchAddress = (project) => {
  //   Geocode.fromLatLng(project?.latitude, project?.longitude).then(
  //     async (response) => {
  //       const address = await response.results[0].formatted_address;
  //       console.log(address);
  //       formik.setFieldValue("cityName", address);
  //       setValue(address);
  //     },
  //     (error) => {
  //       // return setValue(error.message);
  //     }
  //   );
  // };
  // const handleProjectChange = async (project) => {
  //   if (project === "Select Project") {
  //     return formik.setFieldValue("projectId", "Select Project");
  //   }
  //   if (project?.latitude !== 0) {
  //     formik.setFieldValue("latitude", project?.latitude);
  //     formik.setFieldValue("longitude", project?.longitude);
  //     fetchAddress(project);
  //   } else {
  //     setValue("This project lat, lng has 0");
  //   }
  //   formik.setFieldValue("projectId", project);
  // };
  // console.log("formik.values.projectId", formik.values);

  const handleProjectChange = useCallback(
    async (project) => {

      formik.setFieldValue('projectId', project);
      formik.setFieldValue('latitude', project.latitude);
      formik.setFieldValue('longitude', project.longitude);
      if (project?.latitude !== 0) {
        try {
          const response = await Geocode.fromLatLng(
            project.latitude,
            project.longitude
          );
          const address = response.results[0].formatted_address;
          formik.setFieldValue('cityName', address);
          // setValue(address);
        } catch (error) {
          formik.setFieldValue('cityName', null);

          // setValue(error.message);
        }
      } else {
        formik.setFieldValue('cityName', null);
      }
    },
    [formik.values.projectId?.projectId]
  );

  const handleRowInputField = (field, id, value) => {
    setlistOfRow((prevRows) =>
      prevRows.map((item) => {
        if (item.id === id) {
          return {
            ...item,
            [field]: value,
          };
        }
        return item;
      })
    );
  };
  const askConfirmation = (isDelete) => {
    let toastId;
  };
  const addNewRow = () => {
    setlistOfRow((preState) => [
      ...preState,
      {
        id: uuidv4(),
        skillId: 'Select Skill',
        skillTypeId: 'Supervisor',
        isNMR: true,
        requiredWorkers: '',
        manDays: '',
        description: '',
        dailyWage: '',
      },
    ]);
  };
  const deleteRow = (id) => {
    const procced = () => {
      toast.dismiss(toastId);
      setlistOfRow((preState) => preState.filter((item) => item.id !== id));
    };
    const cancel = () => {
      toast.dismiss(toastId);
    };
    let toastId = toast(
      <div
        style={
          {
            // background: '#777',
            // color: '#fff',
          }
        }
      >
        <Typography>Are you sure ?</Typography>
        <Grid container justify="flex-end" style={{ marginTop: '10px' }}>
          <Button
            style={{
              color: '#fff',
              backgroundColor: 'green',
              textTransform: 'capitalize',
            }}
            onClick={procced}
          >
            Yes
          </Button>
          <Button
            style={{
              color: '#fff',
              backgroundColor: 'tomato',
              marginLeft: '10px',
              textTransform: 'capitalize',
            }}
            onClick={cancel}
          >
            cancel
          </Button>
        </Grid>
      </div>
    );
  };

  return (
    <Page className={classes.root} id="zoom-level" title="Create Jobs">
      <form
        onSubmit={formik.handleSubmit}
        style={{
          width: '98%',

          // position: 'relative',
          // height: '90vh',
          backgroundColor: '#fff',
          borderRadius: '10px',
          marginLeft: '10px',
          marginBottom: '10px',
        }}
      >
        <Grid container xs={12}>
          <Grid item container xs={12}>
            <Grid
              container
              style={{
                paddingLeft: '10px',
                paddingTop: '10px',
              }}
            >
              <Typography
                variant="h5"
                className={classes.heading}
                style={{ paddingBottom: '1rem' }}
              >
                Create New Job
              </Typography>

              <Grid container>
                <GlobalRow
                  classes={classes}
                  selectedJob={selectedJob}
                  projectsList={projectsList}
                  formik={formik}
                  handleProjectChange={handleProjectChange}
                ></GlobalRow>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{}}
            >
              {listOfRow?.map((row, index) => (
                <JobRowItem
                  row={row}
                  key={row.id}
                  handleRowInputField={handleRowInputField}
                  classes={classes}
                  skillsList={skillsList}
                  addNewRow={addNewRow}
                  isLastItem={listOfRow?.length - 1 === index}
                  index={index}
                  deleteRow={deleteRow}
                  checkHover={(id) =>
                    setlistOfRow((pre) =>
                      pre.map((item) =>
                        item.id === id
                          ? { ...item, isHovered: true }
                          : { ...item, isHovered: false }
                      )
                    )
                  }
                  validateRowFields={validateRowFields}
                />
              ))}
              <Grid
                container
                item
                style={{
                  // position: 'absolute',
                  // bottom: '0',
                  paddingBottom: '10px',
                  width: '98%',
                }}
                justifyContent="space-between"
              >
                <Grid item>
                  <Button
                    className={classes.addNewJob}
                    startIcon={<AddBoxIcon style={{ color: 'blue' }} />}
                    onClick={addNewRow}
                  >
                    Add New Job
                  </Button>
                </Grid>
                <Grid item container xs={3} justifyContent="flex-end">
                  <Grid
                    item
                    style={{
                      marginRight: 15,
                    }}
                  >
                    <Button
                      disabled={loading}
                      className={classes.buttonGreen}
                      style={{
                        marginRight: 15,
                        width: '100px',

                        textTransform: 'capitalize',
                      }}
                      type="submit"
                      startIcon={<WorkIcon />}
                      onClick={formik.submitForm}
                    >
                      {selectedJob ? 'Update' : 'Create'}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className={classes.buttonPurple}
                      style={{
                        width: '100px',

                        textTransform: 'capitalize',
                      }}
                      onClick={() => {
                        if (selectedJob) {
                          return navigate('..detail');
                        }
                        navigate('..');
                        dispatch(selectJob(null));
                      }}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Page>
  );
};
export default CreateJob;
// !
